import { IData } from 'shared/types/dataType'

export const glossary = ({ language }: IData) => {
  return {
    fr: {
      title: 'Glossaire',
      paragraphs: [
        {
          title: 'A',
          content: `
**Abonnés Haut Débit et Très Haut Débit (ou Broadband) :** abonnés ayant souscrit une offre xDSL, câble ou Fibre du Groupe.

**ARPU Haut Débit et Très Haut Débit (Revenu Moyen par Abonné Haut Débit et Très Haut Débit) :** inclut le chiffre d’affaires généré par le forfait et les services à valeur ajoutée, mais exclut le chiffre d’affaires non récurrent (par exemple les frais de migration d’une offre à une autre ou les frais de résiliation), divisé par le nombre total d’abonnés Haut Débit et Très Haut Débit facturés sur le dernier mois du trimestre.

**ARPU Mobile facturé aux abonnés :** inclut le chiffre d’affaires facturé à l'abonné divisé par le nombre total d’abonnés Mobile sur la période
`
        },
        {
          title: 'C',
          content: `
**Chiffre d’affaires facturé à l’abonné :** chiffre d’affaires forfait et hors forfait directement facturé à l’abonné.

**Chiffre d’affaires services :** chiffre d’affaires hors ventes d’équipements.
`
        },
        {
          title: 'E',
          content: `
**EBITDAaL :** résultat opérationnel courant avant prise en compte des dotations aux amortissements (ou dépréciations) des immobilisations corporelles et incorporelles, et de l’impact des charges liées aux rémunérations sous forme de stock-options/actions.

**Endettement net :** différence entre les passifs financiers à court et long terme et la trésorerie disponible au bilan.
          `
        },
        {
          title: 'F',
          content: `
**FCF :** Free Cash Flow,

**Fibre :** est une solution de desserte fibre optique de bout en bout entre le central de raccordement (NRO) et l'utilisateur

`
        },
        {
          title: 'L',
          content: `
**LTM :** les 12 derniers mois (LTM pour « Last Twelve Months »).

`
        },
        {
          title: 'M',
          content: `
**M2M :** communications de machine à machine.

`
        },
        {
          title: 'N',
          content: `
**Nombre d’abonnés Haut Débit et Très Haut Débit France :** représente, à la fin de la période mentionnée, le nombre total d’abonnés identifiés par leur ligne téléphonique qui ont souscrit à une offre de Free après élimination de ceux pour lesquels une résiliation a été enregistrée.

**Nombre d’abonnés mobiles France :** représente, à la fin de la période mentionnée, le nombre total d’abonnés identifiés par leur ligne téléphonique qui ont souscrit à une des offres mobiles de Free après élimination de ceux pour lesquels une résiliation a été enregistrée.

**Nombre d’abonnés Fibre Italie :** représente, à la fin de la période mentionnée, le nombre total d’abonnés identifiés par leur ligne téléphonique qui ont souscrit à une offre Fibre d’iliad Italia après élimination de ceux pour lesquels une résiliation a été enregistrée.

**Nombre d’abonnés mobiles Italie :** représente, à la fin de la période mentionnée, le nombre total d’abonnés identifiés par leur ligne téléphonique qui ont souscrit à une des offres mobiles de iliad Italia, et qui ont été émis ou reçu une communication au moins une fois au cours des 3 derniers mois.

**Nombre d’abonnés mobiles Pologne :** représente, à la fin de la période mentionnée, le nombre total d’abonnés identifiés par leur ligne téléphonique qui ont souscrit à une des offres mobiles du groupe Play (hors M2M et hors cartes SIM offertes), et qui ont été émis ou reçu une communication (voix ou data) au moins une fois au cours des 30 derniers jours.

**Nombre d’abonnés Home Pologne :** représente, à la fin de la période mentionnée, le nombre d’abonnés qui ont souscrit à une offre TV Box ou une offre Haut et Très Haut Débit fixe après élimination de ceux pour lesquels une résiliation a été enregistrée.

**Nombre total d’abonnés Pologne :** représente, à la fin de la période mentionnée, le nombre d’abonnés mobiles actifs Pologne et le nombre d’abonnés Home Pologne.

          `
        },
        {
          title: 'O',
          content: `
**OFCF :** Free Cash Flow Opérationnel (EBITDAaL moins capex).

**Opérateur alternatif :** opérateur apparu à la suite de la perte de monopole de l’opérateur historique d’Etat.
`
        },
        {
          title: 'P',
          content: `
**Prise raccordable Fibre :** prise pour laquelle le lien entre le point de mutualisation et le point de branchement optique a été réalisé par l’opérateur d’immeuble, à laquelle le Groupe peut accéder en application de ses engagements de cofinancement, et pour laquelle les travaux de raccordement au réseau du Groupe sont achevés ou en cours d’achèvement.
`
        },
        {
          title: 'R',
          content: `
**Recrutement :** correspond à la différence entre le nombre total d’abonnés à la fin de deux périodes différentes.

**Ratio d’endettement (ou Leverage) :** correspond au rapport entre la dette nette (passif financier court et long terme moins la trésorerie et équivalents de trésorerie) et l’EBITDAaL.

`
        },
        {
          title: 'T',
          content: `
**Taux d’adoption de la Fibre :** correspond au rapport en France entre le nombre d’abonnés Fibre et le nombre d’abonnés Haut Débit et Très Haut Débit.

`
        }
      ]
    },
    en: {
      title: 'Glossary',
      paragraphs: [
        {
          title: 'A',
          content: `
**Alternative operator :** An operator that entered the market subsequent to the incumbent State operator losing its monopoly.
`
        },
        {
          title: 'B',
          content: `**Broadband and Ultra-Fast Broadband ARPU (Average Revenue Per Broadband and Ultra- Fast Broadband User) :** Includes revenues from the flat-rate package and value-added services but excludes one-time revenues (e.g., fees for switching from one offer to another or cancellation fees), divided by the total number of Broadband and Ultra-Fast Broadband subscribers billed for the last month of the quarter.

 

**Broadband and Ultra-Fast Broadband subscribers :** Subscribers who have signed up for the Group’s xDSL, Cable or Fiber offerings.`
        },
        {
          title: 'C',
          content: `**Connectible Fiber socket :** A socket for which the link between the shared access point and the optical splitter has been put in place by the building operator, which the Group can access in accordance with its co-financing commitments, and for which the connection to the Group’s network has been completed or is in progress.`
        },
        {
          title: 'E',
          content: `**EBITDAaL :** Profit from ordinary activities before depreciation, amortization and impairment of property, plant and equipment and intangible assets, and the impact of share-based payment.`
        },
        {
          title: 'F',
          content: `**FCF :** Free Cash Flow.

 

**Fiber :** Data delivery technology that directly connects subscribers to an optical node (ON).

 

**Fiber take-up rate :** Represents the number of Fiber subscribers as a percentage of the total number of Broadband and Ultra-Fast Broadband subscribers.`
        },
        {
          title: 'L',
          content: `**Leverage ratio :** Represents the ratio between net debt (short- and long-term financial liabilities less cash and cash equivalents) and EBITDAaL.

 

**LTM :** Last twelve months.`
        },
        {
          title: 'M',
          content: `**M2M :** Machine to machine communications.

 

**Mobile ARPU billed to subscribers :** Includes revenues billed to subscribers divided by the total number of Mobile subscribers during the period.`
        },
        {
          title: 'N',
          content: `**Net adds :** Represents the difference between the total number of subscribers at the end of two different periods.

 

**Net debt :** Difference between short- and long-term financial liabilities, and available cash and cash equivalents as presented in the balance sheet.

 

**Number of Broadband and Ultra-Fast Broadband subscribers – France :** Represents, at the end of a given period, the total number of subscribers, identified by their telephone lines, who have subscribed to a Free Broadband or Ultra-Fast Broadband offering, excluding those recorded as having requested the termination of their subscription.

 

**Number of Fiber subscribers – Italy :** Represents, at the end of a given period, the total number of subscribers, identified by their telephone lines, who have subscribed to an iliad Italia Fiber offering, excluding those recorded as having requested the termination of their subscription.

 

**Number of Home subscribers – Poland :** Represents, at the end of a given period, the number of subscribers who have subscribed to a TV Box plan or a fixed Broadband or Ultra-Fast Broadband plan, excluding those recorded as having requested the termination of their subscription.

 

**Number of mobile subscribers – France :** Represents, at the end of a given period, the total number of subscribers, identified by their telephone lines, who have subscribed to a Free mobile offering, excluding those recorded as having requested the termination of their subscription.

 

**Number of mobile subscribers – Italy :** Represents, at the end of a given period, the total number of subscribers, identified by their telephone lines, who have subscribed to an iliad Italia mobile offering (B2C and B2B) and who have issued or received at least one communication during the preceding three months.

 

**Number of mobile subscribers – Poland :** Represents, at the end of a given period, the total number of subscribers, identified by their telephone lines, who have subscribed to a Play mobile offering (excluding M2M and free SIM cards) and who have issued or received at least one communication (voice or data) during the preceding 30 days.`
        },
        {
          title: 'O',
          content: `**OFCF :** Operating free cash flow (EBITDAaL less capex).`
        },
        {
          title: 'R',
          content: `**Revenues billed to subscribers :** Revenues generated from services billed directly to subscribers (services included in subscribers’ mobile plans, as well as additional services).`
        },
        {
          title: 'S',
          content: `**Services revenues :** Revenues excluding sales of devices.`
        },
        {
          title: 'T',
          content: `**Total number of subscribers – Poland :** Represents, at the end of a given period, the number of active mobile subscribers in Poland and the number of Home subscribers in Poland.`
        }
      ]
    }
  }[language]
}
