import { Action } from 'redux'
import { List } from 'immutable'

// State

export interface ILocaleState {
  availableLanguages: List<ILanguage>
  defaultLanguage: string
  defaultLuxonCode: string
  currentLanguage: string
  texts: {
    language?: string
    data: IFeaturedTextHash
  }
}

export interface ILanguage {
  code: string
  shortName: string
  luxonCode: string
}

export interface ILocalizedLanguage extends ILanguage {
  name: string
}

export interface IHash<T> {
  [key: string]: T
}

export type IFeaturedTextHash = IHash<ITextHash>
export type ITextHash = IHash<string>

// Actions
export enum LocaleActionKind {
  UpdateLanguage = 'LOCALE_UPDATE_LANGUAGE'
}

export interface IUpdateLanguage extends Action {
  readonly type: LocaleActionKind.UpdateLanguage
  readonly meta: { language: string }
}

export type LocaleAction = IUpdateLanguage
