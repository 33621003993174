import { Action } from 'redux'
import { List } from 'immutable'

export interface ICountryState {
  countries: List<ICountrySet>
}

export interface ICountrySet {
  region: string
  alpha2Code: string
  displayName: string
}

export enum CountryActionKind {
  Fetch = 'COUNTRIES_FETCH',
  Update = 'COUNTRIES_UPDATE'
}

export interface IFetch extends Action {
  readonly type: CountryActionKind.Fetch
  readonly meta: {
    language: string
  }
}

export interface IUpdate extends Action {
  readonly type: CountryActionKind.Update
  readonly payload: {
    countries: List<ICountrySet>
  }
}

export type CountriesAction = IFetch | IUpdate
