import { createTheme } from '@material-ui/core'

export const theme = createTheme({
  palette: {
    primary: {
      light: '#d63333',
      main: '#cc0000',
      dark: '#8e0000',
      contrastText: ''
    },
    secondary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: ''
    }
  },
  typography: {
    fontFamily: ['Montserrat', 'Roboto', 'Arial', 'sans-serif', 'Iliad-Regular'].join(',')
  },
  overrides: {
    MuiTab: {
      root: {
        maxWidth: 'none'
      }
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#FFFFFF'
        }
      }
    },
    MuiButtonBase: {
      root: {
        '&$focusVisible': {
          backgroundColor: 'none'
        }
      }
    },
    MuiList: {
      root: {
        '& a': {
          color: 'inherit',
          textDecoration: 'none',
          padding: '1rem',
          width: '100%'
        }
      },
      padding: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
      }
    },
    MuiListItem: {
      root: {
        '&$focusVisible': {
          backgroundColor: 'inherit'
        }
      },
      button: {
        '&:hover': {
          color: '#ffffff',
          backgroundColor: '#cc0000 !important'
        }
      }
    },
    MuiMenuItem: {
      root: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
      }
    },
    MuiDialog: {
      paperFullScreen: {
        display: 'block'
      }
    },
    MuiCircularProgress: {
      circleStatic: {
        transition: '0'
      }
    }
  }
})
