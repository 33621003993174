import React, { Suspense, useEffect } from 'react'
import { usePath, useRoutes } from 'hookrouter'
import { rootRoutes } from './routes'
import { useFetchBreakpoint } from '../store/navigation/hooks/useFetchBreakpoint'
import 'swiper/swiper-bundle.min.css'
import { trackPageView } from '../shared/services/matomoTools'

export const App = () => {
  const path = usePath()
  useFetchBreakpoint()

  useEffect(() => {
    window.scrollTo(0, 0)
    trackPageView()
  }, [path])

  return <Suspense fallback={<div></div>}>{useRoutes(rootRoutes)}</Suspense>
}
