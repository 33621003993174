import { Action } from 'redux'
import { VariantType } from 'notistack'

export interface INotificationState {
  message?: string
  severity: VariantType
}

export enum NotificationActionKind {
  Notify = 'NOTIFICATION_NOTIFY',
  Reset = 'NOTIFICATION_RESET'
}

export interface INotify extends Action {
  readonly type: NotificationActionKind.Notify
  readonly payload: {
    message: string
    severity: VariantType
  }
}

export interface IReset extends Action {
  readonly type: NotificationActionKind.Reset
}

export type NotificationAction = INotify | IReset
