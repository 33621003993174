import { Action } from 'redux'

export interface INavigationState {
  breakpoint?: string
}

export enum NavigationActionKind {
  UpdateBreakpoint = 'NAVIGATION_UPDATE_BREAKPOINT'
}

export interface IUpdateBreakpoint extends Action {
  readonly type: NavigationActionKind.UpdateBreakpoint
  readonly payload: {
    breakpoint: string
  }
}

export type NavigationAction = IUpdateBreakpoint
