import { CountriesAction, CountryActionKind, ICountrySet, ICountryState } from './types'
import { List } from 'immutable'

export const initialCountryState: ICountryState = {
  countries: List<ICountrySet>([])
}

export const countryReducer = (state: ICountryState = initialCountryState, action: CountriesAction) => {
  switch (action.type) {
    case CountryActionKind.Update:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
