const routePaths = {
  home: '/',
  pressYear: 'media/communiques-de-presse/:year',
  pressYearTag: 'media/communiques-de-presse/:year/:tag',
  investorGroupAssembly: 'investisseurs/groupe/assemblee-generale',
  investorContactAgenda: 'investisseurs/contact-et-agenda',
  investorGroupPress: 'investisseurs/groupe/communiques-de-presse-financiers',
  investorGroupPressYear: 'investisseurs/groupe/communiques-de-presse-financiers/:year',
  investorGroupPressYearTag: 'investisseurs/groupe/communiques-de-presse-financiers/:year/:tag',
  investorHoldingPress: 'investisseurs/holding/communiques-de-presse-financiers',
  investorHoldingPressYear: 'investisseurs/holding/communiques-de-presse-financiers/:year',
  investorHoldingPressYearTag: 'investisseurs/holding/communiques-de-presse-financiers/:year/:tag',
  investorGroupDebt: 'investisseurs/groupe/dette',
  investorHoldingDebt: 'investisseurs/holding/dette',
  investorGroupInformationYear: 'investisseurs/groupe/information-reglementee/:year',
  investorGroupLegalDocuments: 'investisseurs/groupe/documents-legaux/:year',
  investorGroupResultYear: 'investisseurs/groupe/resultats/:year',
  investorGroupResultYearTag: 'investisseurs/groupe/resultats/:year/:tag',
  investorHoldingResultYear: 'investisseurs/holding/resultats/:year',
  investorHoldingResultYearTag: 'investisseurs/holding/resultats/:year/:tag',
  investorGroupStructure: 'investisseurs/groupe/structure-du-groupe',
  iliadGroup: 'le-groupe/la-revolution-iliad',
  iliadEthic: 'le-groupe/ethique-et-conformite',
  iliadInnovation: 'le-groupe/25-ans-innovation',
  iliadMotto: 'le-groupe/devise',
  iliadWhoAreWe: 'le-groupe/qui-sommes-nous',
  iliadGovernance: 'le-groupe/gouvernance',
  netWorkVision: 'nos-reseaux/vision',
  networkPresence: 'nos-reseaux/presence-en-france',
  networkKeyFigures: 'nos-reseaux/chiffres-cles',
  networkRelayAntennasInstallation: 'nos-reseaux/antennes-relais',
  networkFreeSimboxInstallation: 'nos-reseaux/borne-free',
  networkTestimonials: 'nos-reseaux/temoignages',
  commitmentDoThingsRight: 'nos-engagements/faire-les-choses-bien',
  commitmentRseReleaseViewYear: 'publications/:year',
  commitmentRseReleaseViewYearTag: 'publications/:year/:tag',
  commitmentAssociate: 'nos-engagements/pour-nos-collaborateurs',
  commitmentEnvironment: 'nos-engagements/pour-l-environnement',
  commitmentSociety: 'nos-engagements/pour-la-societe',
  commitmentESGDocuments: 'nos-engagements/documents-esg/:year',
  jobOurValues: 'carrieres/nos-valeurs',
  job: 'carrieres/metiers',
  jobWhyJoinUs: 'carrieres/pourquoi-nous-rejoindre',
  personnalData: 'donnees-personnelles',
  legalNotices: 'mentions-legales',
  arcepYear: 'qualite-de-service/:year',
  arcepYearTag: 'qualite-de-service/:year/:tag',
  documentationYear: 'documentation/:year',
  documentationYearTag: 'documentation/:year/:tag',
  contact: 'contact/confirmation',
  newsTitle: 'actualites/article/:title',
  newsYearTag: 'actualites/:year/:tag',
  iliadTribute: 'hommage-nicolasjaeger'
}

module.exports = { routePaths }
