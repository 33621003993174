import { CountryActionKind, ICountrySet, IFetch, IUpdate } from '../types'
import { IApplicationState } from 'store/combiners'
import { Epic, ofType } from 'redux-observable'
import { catchError, flatMap, mergeMap } from 'rxjs/operators'
import { ajax, AjaxError } from 'rxjs/ajax'
import { apis } from 'store/apis'
import { updateCountries } from '../actions'
import { List } from 'immutable'
import { of } from 'rxjs'

export const fetchCountriesEpic: Epic<IFetch, any, IApplicationState, IUpdate> = (action$) =>
  action$.pipe(
    ofType(CountryActionKind.Fetch),
    mergeMap((action) =>
      ajax.getJSON<ICountrySet[]>(apis.countries.getCountriesApi(action.meta.language)).pipe(
        flatMap((countries: ICountrySet[]) => of(updateCountries(List(countries)))),
        catchError((_: AjaxError) => of(updateCountries(List([]))))
      )
    )
  )
