import useSWR, { cache } from 'swr'
import axios from 'axios'

const defaultFetcher = (url) => axios.get(url).then((result) => result.data)

interface useStaticSWRProps {
  key: string | any[]
  fetcher?: any
  refreshInterval?: number
}

export const useStaticSWR = ({ key, fetcher = defaultFetcher, refreshInterval = 0 }: useStaticSWRProps): any => {
  return useSWR(key, fetcher, {
    revalidateOnMount: !cache.has(key),
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval
  })
}
