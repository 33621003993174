import { IData } from 'shared/types/dataType'

export const ourValues = ({ language, breakpoint }: IData) => {
  return {
    fr: {
      header: {
        banner: {
          title: 'Nos Valeurs',
          image: {
            alt: 'actualites',
            urlMobile: '/assets/images/career/our-values/our-values-mobile.png',
            urlDesktop: '/assets/images/career/our-values/our-values-desktop.png'
          }
        },
        standFirst:
          'Les collaborateurs du Groupe partagent tous un état d’esprit bien particulier. Cet esprit Free, nous avons cherché à le définir en nous appuyant sur le ressenti des équipes. A l’issue d’un atelier RH, quatre valeurs ont fait l’unanimité pour expliquer ce que nous sommes.'
      },
      paragraphs: [
        {
          isQuinconce: true,
          image: '/assets/images/career/our-values/valeur-autonomie.gif',
          title: 'Autonomie',
          content: `
Nous sommes autonomes au sens où nous aimons, dans le respect du cadre fixé, **prendre des initiatives**, ce qui implique **responsabilité** et **confiance**. Nous voulons aussi **faire les choses par nous-mêmes**, sans dépendre des autres.
`
        },
        {
          isQuinconce: true,
          image: '/assets/images/career/our-values/valeurs-audace.gif',
          title: 'Audace',
          content: `
Nous sommes audacieux car l’**innovation** fait partie de notre ADN. Nous prenons des risques et nous assumons le **droit à l’erreur**. Pour nous, être audacieux, c’est savoir **sortir de sa zone de confort**, ce qui permet souvent de grandir.
`
        },
        {
          isQuinconce: true,
          image: '/assets/images/career/our-values/valeurs-flexibilite.gif',
          title: 'Flexibilité',
          content: `
Nous sommes flexibles car nous faisons preuve au quotidien de **souplesse**, d’**agilité** et de **réactivité**. Tel le couteau-suisse toujours prêt pour toutes les situations !
`
        },
        {
          isQuinconce: true,
          image: '/assets/images/career/our-values/valeurs-efficacite.gif',
          title: 'Efficacité',
          content: `
Nous sommes efficaces parce que jour après jour, nous cherchons tous à atteindre nos objectifs en **nous appuyant les uns sur les autres**. Cette **réussite collective** fait notre fierté.
`
        }
      ]
    },
    en: {
      header: {
        banner: {
          title: 'Our values',
          image: {
            alt: 'our values',
            urlMobile: '/assets/images/career/our-values/our-values-uk-mobile.png',
            urlDesktop: '/assets/images/career/our-values/our-values-uk-desktop.png'
          }
        },
        standFirst:
          'The Group’s people all share the distinctive Free spirit. We wanted to try and define this mindset, so we sounded out our teams. An HR workshop was set up and it was unanimously agreed that the following four core values sum up who and what we are:'
      },
      paragraphs: [
        {
          isQuinconce: true,
          image: '/assets/images/career/our-values/valeur-autonomie.gif',
          title: 'Autonomy',
          content: `
We’re autonomous in the sense that, while respecting a given framework, we like to **take initiatives**. This implies being **responsible and confident**. And we like to **do things by ourselves**, without depending on others.
`
        },
        {
          isQuinconce: true,
          image: '/assets/images/career/our-values/valeurs-audace.gif',
          title: 'Boldness',
          content: `
We’re bold because **innovation is in our DNA**. We take risks and accept that everyone can **make mistakes**. For us, being bold means **stepping out of our comfort zone**, which is often the best way to develop and grow.
`
        },
        {
          isQuinconce: true,
          image: '/assets/images/career/our-values/valeurs-flexibilite.gif',
          title: 'Flexibility',
          content: `
We’re flexible because we’re **versatile, agile and reactive** on a daily basis. We’re like a **Swiss Army Knife** – always ready for any situation!
`
        },
        {
          isQuinconce: true,
          image: '/assets/images/career/our-values/valeurs-efficacite.gif',
          title: 'Effectiveness',
          content: `
We’re effective because, day in, day out, we all strive to reach our objectives **by helping each other**. And it’s this **successful teamwork** that makes us proud to be who and what we are.
`
        }
      ]
    }
  }[language]
}
