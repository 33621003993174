import { INavigationState, NavigationAction, NavigationActionKind } from './types'

export const initialNavigationState: INavigationState = {}

export const navigationReducer = (state: INavigationState = initialNavigationState, action: NavigationAction) => {
  switch (action.type) {
    case NavigationActionKind.UpdateBreakpoint:
      return {
        ...state,
        breakpoint: action.payload.breakpoint
      }
    default:
      return state
  }
}
