import { INotificationState, NotificationAction, NotificationActionKind } from './types'

export const initialNotificationState: INotificationState = {
  message: undefined,
  severity: 'default'
}

export const notificationReducer = (
  state: INotificationState = initialNotificationState,
  action: NotificationAction
) => {
  switch (action.type) {
    case NotificationActionKind.Notify:
      return {
        ...state,
        ...action.payload
      }
    case NotificationActionKind.Reset:
      return {
        ...initialNotificationState
      }
    default:
      return state
  }
}
