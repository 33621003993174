import React, { lazy } from 'react'
import { HookRouter } from 'hookrouter'
import { EEntity, PublicationType } from '../hooks/usePublications'
import { twentyYearsInnovation } from 'data/group/twenty-years-innovation'
import { vision } from 'data/network/vision'
import { associateCommitments } from 'data/commitments/associate-commitment'
import { environmentCommitments } from 'data/commitments/environment-commitment'
import { societyCommitments } from 'data/commitments/society-commitment'
import { ourValues } from 'data/commitments/our-values'
import { whyJoinUsCommitments } from 'data/commitments/why-join-us-commitment'
import { personalData } from 'data/footer/personal-data'
import { glossary } from 'data/footer/glossary'
import { legalNotices } from 'data/footer/legal-notices'
import { revolutionIliad } from 'data/group/revolution-iliad'
import { ethicCompliance } from 'data/group/ethic-compliance'
import { devise } from 'data/group/devise'
import { routePaths } from '../routePaths'
import { groupStructure } from 'data/investors/groupStructure'
import { configurationsService } from 'shared/services/ConfigurationsService'
import { TributeView } from './views/TributeView'

const RootRedirect = lazy(() => import(/* webpackChunkName: "RootRedirect" */ './RootRedirect'))
const LocalizedApp = lazy(() => import(/* webpackChunkName: "LocalizedApp" */ './LocalizedApp'))
const Layout = lazy(() => import(/* webpackChunkName: "Layout" */ './Layout'))
const HomeView = lazy(() => import(/* webpackChunkName: "HomeView" */ './views/HomeView'))
const PressReleasesView = lazy(() => import(/* webpackChunkName: "PressReleasesView" */ './views/PressReleasesView'))
const ShareHoldersMeetingView = lazy(
  () => import(/* webpackChunkName: "ShareHoldersMeetingView" */ './views/ShareHoldersMeetingView')
)
const CalendarContactView = lazy(
  () => import(/* webpackChunkName: "CalendarContactView" */ './views/CalendarContactView')
)
const FinancialReleasesView = lazy(
  () => import(/* webpackChunkName: "FinancialReleasesView" */ './views/FinancialReleasesView')
)
const DebtView = lazy(() => import(/* webpackChunkName: "DebtView" */ './views/DebtView'))
const RegulatedInformationView = lazy(
  () => import(/* webpackChunkName: "RegulatedInformationView" */ './views/RegulatedInformationView')
)
const LegalDocumentsView = lazy(() => import(/* webpackChunkName: "LegalDocumentsView" */ './views/LegalDocumentsView'))
const FinancialResultsView = lazy(
  () => import(/* webpackChunkName: "FinancialResultsView" */ './views/FinancialResultsView')
)
const EditoView = lazy(() => import(/* webpackChunkName: "EditoView" */ './views/EditoView'))
const EditoContribView = lazy(() => import(/* webpackChunkName: "EditoContribView" */ './views/EditoContribView'))
const WhoAreWeView = lazy(() => import(/* webpackChunkName: "WhoAreWeView" */ './views/WhoAreWeView'))
const GovernanceView = lazy(() => import(/* webpackChunkName: "GovernanceView" */ './views/GovernanceView'))
const OurPresenceView = lazy(() => import(/* webpackChunkName: "OurPresenceView" */ './views/OurPresenceView'))
const KeyFigureView = lazy(() => import(/* webpackChunkName: "KeyFigureView" */ './views/KeyFigureView'))
const DoThingsRightView = lazy(() => import(/* webpackChunkName: "DoThingsRightView" */ './views/DoThingsRightView'))
const JobView = lazy(() => import(/* webpackChunkName: "JobView" */ './views/JobView'))
const TestimonialsView = lazy(() => import(/* webpackChunkName: "TestimonialsView" */ './views/TestimonialsView'))
const FooterPageView = lazy(() => import(/* webpackChunkName: "FooterPageView" */ './views/FooterPageView'))
const ARCEPIndicatorsView = lazy(
  () => import(/* webpackChunkName: "ARCEPIndicatorsView" */ './views/ARCEPIndicatorsView')
)
const DocumentationView = lazy(() => import(/* webpackChunkName: "DocumentationView" */ './views/DocumentationView'))
const ContactView = lazy(() => import(/* webpackChunkName: "ContactView" */ './views/ContactView'))
const ArticleView = lazy(() => import(/* webpackChunkName: "ArticleView" */ './views/ArticleView'))
const NewsView = lazy(() => import(/* webpackChunkName: "NewsView" */ './views/NewsView'))
const RseReleasesView = lazy(() => import(/* webpackChunkName: "RseReleasesView" */ './views/RseReleasesView'))
const ESGDocumentsView = lazy(() => import(/* webpackChunkName: "ESGDocumentsView" */ './views/ESGDocumentsView'))
const RelayAntennaInstallationView = lazy(
  () => import(/* webpackChunkName: "RelayAntennaInstallationView" */ './views/RelayAntennaView')
)
const FreeSimboxInstallationView = lazy(
  () => import(/* webpackChunkName: "FreeSimboxInstallationView" */ './views/FreeSimboxInstallationView')
)

export const rootRoutes = {
  '/': () => <RootRedirect />,
  '/:language*': ({ language }: HookRouter.QueryParams) => <LocalizedApp language={language} />
}

export const localizedRoutes = {
  '/*': () => <Layout />
}

export const routes = {
  [routePaths.home]: () => <HomeView />,
  [routePaths.pressYear]: ({ year }: HookRouter.QueryParams) => <PressReleasesView year={year} />,
  [routePaths.pressYearTag]: ({ year, tag }: HookRouter.QueryParams) => (
    <PressReleasesView year={year} tag={(tag as string).length > 0 ? tag : undefined} />
  ),
  [routePaths.investorGroupAssembly]: () => <ShareHoldersMeetingView />,
  [routePaths.investorContactAgenda]: () => <CalendarContactView />,
  [routePaths.investorGroupPress]: () => <RootRedirect />,
  [routePaths.investorGroupPressYear]: ({ year }: HookRouter.QueryParams) => (
    <FinancialReleasesView year={year} entity={EEntity.groupe} />
  ),
  [routePaths.investorGroupPressYearTag]: ({ year, tag }: HookRouter.QueryParams) => (
    <FinancialReleasesView year={year} tag={(tag as string).length > 0 ? tag : undefined} entity={EEntity.groupe} />
  ),
  [routePaths.investorHoldingPress]: () => <RootRedirect />,
  [routePaths.investorHoldingPressYear]: ({ year }: HookRouter.QueryParams) => (
    <FinancialReleasesView year={year} entity={EEntity.holding} />
  ),
  [routePaths.investorHoldingPressYearTag]: ({ year, tag }: HookRouter.QueryParams) => (
    <FinancialReleasesView year={year} tag={(tag as string).length > 0 ? tag : undefined} entity={EEntity.holding} />
  ),
  [routePaths.investorGroupDebt]: () => <DebtView entity={EEntity.groupe} />,
  [routePaths.investorHoldingDebt]: () => <DebtView entity={EEntity.holding} />,
  [routePaths.investorGroupInformationYear]: ({ year }: HookRouter.QueryParams) => (
    <RegulatedInformationView year={year} />
  ),
  [routePaths.investorGroupLegalDocuments]: ({ year }: HookRouter.QueryParams) => <LegalDocumentsView year={year} />,
  [routePaths.investorGroupResultYear]: ({ year }: HookRouter.QueryParams) => (
    <FinancialResultsView year={year} entity={EEntity.groupe} />
  ),
  [routePaths.investorGroupResultYearTag]: ({ year, tag }: HookRouter.QueryParams) => (
    <FinancialResultsView year={year} tag={(tag as string).length > 0 ? tag : undefined} entity={EEntity.groupe} />
  ),
  [routePaths.investorHoldingResultYear]: ({ year }: HookRouter.QueryParams) => (
    <FinancialResultsView year={year} entity={EEntity.holding} />
  ),
  [routePaths.investorHoldingResultYearTag]: ({ year, tag }: HookRouter.QueryParams) => (
    <FinancialResultsView year={year} tag={(tag as string).length > 0 ? tag : undefined} entity={EEntity.holding} />
  ),
  [routePaths.iliadGroup]: () => <EditoView contentProvider={revolutionIliad} />,
  [routePaths.iliadEthic]: () => <EditoView contentProvider={ethicCompliance} className="ethicCompliance" />,
  [routePaths.iliadInnovation]: () => <EditoView contentProvider={twentyYearsInnovation} />,
  [routePaths.iliadMotto]: () => <EditoView contentProvider={devise} />,
  // 'le-groupe/chiffres-cles': () => <EditoView contentProvider={groupKeyNumbers} />,
  [routePaths.iliadWhoAreWe]: () => <WhoAreWeView />,
  [routePaths.iliadGovernance]: () => <GovernanceView />,
  // 'le-groupe/activites': () => <EditoView contentProvider={activities} />,
  // [routePaths.netWorkVision]: () => <EditoView contentProvider={vision} />,
  [routePaths.netWorkVision]: () => (
    <EditoContribView route={configurationsService.visionEndpoint} contentProvider={vision} />
  ),
  [routePaths.investorGroupStructure]: () => <EditoView contentProvider={groupStructure} />,
  [routePaths.networkPresence]: () => <OurPresenceView />,
  [routePaths.networkKeyFigures]: () => <KeyFigureView />,
  [routePaths.networkRelayAntennasInstallation]: () => <RelayAntennaInstallationView />,
  [routePaths.networkFreeSimboxInstallation]: () => <FreeSimboxInstallationView />,
  [routePaths.networkTestimonials]: () => <TestimonialsView />,
  [routePaths.commitmentDoThingsRight]: () => <DoThingsRightView />,
  [routePaths.commitmentRseReleaseViewYear]: ({ year }: HookRouter.QueryParams) => <RseReleasesView year={year} />,
  [routePaths.commitmentRseReleaseViewYearTag]: ({ year, tag }: HookRouter.QueryParams) => (
    <RseReleasesView year={year} tag={(tag as string).length > 0 ? tag : undefined} />
  ),
  [routePaths.commitmentAssociate]: () => <EditoView contentProvider={associateCommitments} />,
  [routePaths.commitmentEnvironment]: () => <EditoView contentProvider={environmentCommitments} />,
  [routePaths.commitmentSociety]: () => <EditoView contentProvider={societyCommitments} />,
  [routePaths.commitmentESGDocuments]: ({ year }: HookRouter.QueryParams) => <ESGDocumentsView year={year} />,
  [routePaths.jobOurValues]: () => <EditoView contentProvider={ourValues} />,
  [routePaths.job]: () => <JobView />,
  [routePaths.jobWhyJoinUs]: () => (
    <EditoContribView route={configurationsService.whyJoinUsEndpoint} contentProvider={whyJoinUsCommitments} />
  ),
  [routePaths.personnalData]: () => <FooterPageView contentProvider={personalData} />,
  glossaire: () => <FooterPageView contentProvider={glossary} />,
  [routePaths.legalNotices]: () => <FooterPageView contentProvider={legalNotices} />,
  [routePaths.arcepYear]: ({ year }: HookRouter.QueryParams) => <ARCEPIndicatorsView year={year} />,
  [routePaths.arcepYearTag]: ({ year, tag }: HookRouter.QueryParams) => (
    <ARCEPIndicatorsView year={year} tag={(tag as string).length > 0 ? tag : undefined} />
  ),
  [routePaths.documentationYear]: ({ year }: HookRouter.QueryParams) => <DocumentationView year={year} />,
  [routePaths.documentationYearTag]: ({ year, tag }: HookRouter.QueryParams) => (
    <DocumentationView year={year} tag={(tag as string).length > 0 ? tag : undefined} />
  ),
  contact: () => <ContactView />,
  [routePaths.contact]: () => <ContactView confirmation />,
  [routePaths.newsTitle]: ({ title }: HookRouter.QueryParams) => <ArticleView title={title} />,
  [routePaths.newsYearTag]: ({ year, tag }: HookRouter.QueryParams) => <NewsView year={year} tag={tag} />,
  [routePaths.iliadTribute]: () => <TributeView />
}

export const routeTemplates = {
  'on-language-change': (language: string, currentPath: string) => {
    const pathParts = currentPath.split('/')
    pathParts[1] = language
    return pathParts.join('/')
  },
  [PublicationType.ARCEPIndicators]: (language: string, year: string, tag: string) =>
    `/${language}/qualite-de-service/${year}/${tag}`,
  [PublicationType.Documentation]: (language: string, year: string, tag: string) =>
    `/${language}/documentation/${year}/${tag}`,
  [PublicationType.FinancialReleases]: (language: string, year: string, tag: string, entity: string) =>
    `/${language}/investisseurs/${entity?.toLowerCase()}/communiques-de-presse-financiers/${year}/${tag}`,
  [PublicationType.FinancialResults]: (language: string, year: string, tag: string, entity: string) =>
    `/${language}/investisseurs/${entity?.toLowerCase()}/resultats/${year}/${tag}`,
  [PublicationType.PressReleases]: (language: string, year: string, tag: string) =>
    `/${language}/media/communiques-de-presse/${year}/${tag}`,
  [PublicationType.RegulatedInformation]: (language: string, year: string, tag: string, entity: EEntity) =>
    `/${language}/investisseurs/${entity?.toLowerCase()}/information-reglementee/${year}`,
  [PublicationType.RseReleases]: (language: string, year: string, tag: string) =>
    `/${language}/publications/${year}/${tag}`,
  [PublicationType.LegalDocuments]: (language: string, year: string) =>
    `/${language}/investisseurs/groupe/documents-legaux/${year}`,
  [PublicationType.ESGDocuments]: (language: string, year: string) =>
    `/${language}/nos-engagements/documents-esg/${year}`
}
