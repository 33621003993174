import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Map } from 'immutable'
import { updateBreakpoint } from '../actions'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

export const useFetchBreakpoint = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const currentBreakpoint = Map<Breakpoint | undefined, boolean>()
    .set('xs', useMediaQuery(theme.breakpoints.only('xs')))
    .set('sm', useMediaQuery(theme.breakpoints.only('sm')))
    .set('md', useMediaQuery(theme.breakpoints.only('md')))
    .set('lg', useMediaQuery(theme.breakpoints.only('lg')))
    .set('xl', useMediaQuery(theme.breakpoints.only('xl')))
    .findKey((value) => value)

  useEffect(() => {
    if (currentBreakpoint !== undefined) {
      dispatch(updateBreakpoint(currentBreakpoint))
    }
  }, [dispatch, currentBreakpoint])
}
