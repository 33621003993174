import { IData } from 'shared/types/dataType'

export const legalNotices = ({ language }: IData) => {
  return {
    fr: {
      title: 'Mentions légales',
      paragraphs: [
        {
          title: 'Editeur : iliad',
          content: `
Société anonyme au capital de 13.102.539,32 €  
N° RCS Paris : 342 376 332  
N° TVA intracommunautaire : FR 82 342 376 332
          `
        },
        {
          title: 'Directeur de la publication',
          content: `
Monsieur Thomas Reynaud
          `
        },
        {
          title: 'Hébergeur',
          content: `
Société anonyme au capital de 13.102.539,32 €  
N° RCS Paris : 342 376 332  
Siège social : 16, rue de la Ville-L’Evêque - 75008 Paris  
Téléphone : 01.73.50.20.00  
          `
        },
        {
          title: 'Données personnelles et Cookies',
          content: `
iliad attache une grande importance à la protection des données à caractère personnel ainsi qu’à votre vie privée. Dans ce cadre, nous vous invitons à consulter la [Politique de confidentialité d’iliad](https://www.iliad.fr/fr/donnees-personnelles). 
          `
        },
        {
          title: 'Informations financières',
          content: `
Ce site ne constitue pas une offre d'actions de la société iliad. Les informations contenues sur ce site ne pourront être prises en compte pour décider de l'acquisition d'actions iliad. Cette décision ne pourra être prise que sur la base des documents publiés en cas d'offre d'actions iliad. En France, une offre d'actions iliad ne sera ouverte qu'après obtention d'un visa de l'Autorité des Marchés Financiers sur un prospectus composé d'un document de base et d'une note d'opération.
          `
        },
        {
          title: 'Propriété intellectuelle et informations du site',
          content: `
iliad est le titulaire exclusif de l’ensemble des droits de propriété intellectuelle relatifs au présent site internet [www.iliad.fr](https://www.iliad.fr/fr/) et son contenu (notamment les photos, logos, marques, information, etc.). Les documents et communiqués téléchargeables sont également protégés par le droit d’auteur. Toute reproduction totale ou partielle du site internet et/ou de tout ou partie de son contenu sans l’autorisation préalable et express d’iliad est interdite et constituerait une contrefaçon.
Les informations contenues sur ce site n'ont fait l'objet d'aucune vérification indépendante par les conseils d’iliad et aucune déclaration ou garantie, expresse ou explicite, sur la justesse, l'exactitude, l'exhaustivité des informations ou des opinions contenues sur ce site n'est faite et ne peut être invoquée par quiconque. iliad n'accepte aucune responsabilité au titre de l'utilisation des informations contenues sur ce site par quiconque.

Le présent site internet peut donner accès à d’autres sites internet qui sont soumis à leurs propres informations légales que nous vous invitons à consulter. iliad ne peut en aucun cas être tenue responsable des informations diffusées sur les sites vers lesquels des liens hypertextes auront été installés ainsi que de tous préjudices résultant de leur accès.
          `
        },
        {
          title: 'Copyrights Photos',
          content: `
**Page Engagements - Pour nos collaborateurs**

Nawale, directrice de centre de contact : Maxime Riché  
Byron, conseiller commercial en Free Center : Maxime Riché  
Ning, responsable logistique : Maxime Riché  
Murat, technicien Télécoms : Maxime Riché  

**Page Engagements - Pour l’environnement**  

Simulation logicielle de la Freebox Pop : Alexandre Da Silva 

**Page Engagements - Pour la société**  

Le Groupe conçoit en interne ses propres solutions de sécurité : Alexandre Da Silva  
DécouvPro Innov'Avenir : Les entreprises pour la cité

**Page Réseaux - Vision**  

En 2020, Free a franchi le cap des 2 millions d’abonnés Fibre : Maxime Riché  
Héliportage d’une antenne mobile à Triel-sur-Seine (78) en juin 2020 : next-Op / H.Koch  

**Page Carrières – Pourquoi nous rejoindre**  

Lancement de la Freebox Pop : Sylvain Leurent  
1ère promotion d'alternants de l'Université Free - Janvier 2021 : Maxime Riché  
Validation des acquis de l’expérience - Remise de diplômes : Jacques Chung
`
        }
      ]
    },
    en: {
      title: 'Legal Notices',
      paragraphs: [
        {
          title: 'Publisher: iliad',
          content: `
A French public limited company (Société anonyme) with share capital of €13,102,539.32  
Registered with the Paris Trade and Companies Register under no. 342 376 332  
Registered office: 16 rue de la Ville L’Evêque, 75008 Paris, France  
Phone: +33 (0)1.73.50.20.00  
  `
        },
        {
          title: 'Director of publication',
          content: `
Thomas Reynaud
          `
        },
        {
          title: 'Hosting provider: iliad',
          content: `
A French public limited company (Société anonyme) with share capital of €13,102,539.32  
Registered with the Paris Trade and Companies Register under no. 342 376 332  
Registered office: 16 rue de la Ville L’Evêque, 75008 Paris, France  
Phone: +33 (0)1.73.50.20.00  
  `
        },
        {
          title: 'Personal data and cookies',
          content: `
iliad takes the protection of personal data and your privacy very seriously.  
For more information, please refer to [iliad's Privacy Policy](https://www.iliad.fr/en/donnees-personnelles).  
  `
        },
        {
          title: 'Financial Information',
          content: `
This website does not constitute an offer of iliad shares. The information provided on this website may not be taken into account when deciding whether to acquire iliad shares. Such a decision should only be made on the basis of the documents published in the event of an offer of iliad shares. In France, an offer of iliad shares can only be made once the Autorité des Marchés Financiers (the French securities regulator) has approved a prospectus consisting of a registration document and an offering circular.
  `
        },
        {
          title: 'Intellectual property and site information',
          content: `
iliad is the exclusive owner of all intellectual property rights relating to this website [www.iliad.fr](https://www.iliad.fr/en/) and its content (including photos, logos, trademarks, information, etc.). Downloadable documents and news releases are also protected by copyright. Any total or partial reproduction of the website and/or all or part of its content without iliad’s prior and express authorization is prohibited and shall constitute an infringement of copyright. 

The information contained on this site has not been independently verified by iliad’s advisors and no express or implied representation or warranty is made as to the correctness, accuracy, and/or completeness of the information or the opinions contained on this site and no such representation or warranty may be relied upon by any person. iliad disclaims any liability in connection with the use by any person whatsoever of the information contained on this site.

This website may provide access to other websites which are subject to their own legal notices which we advise you to read.
`
        },
        {
          title: 'Photo credits and copyright holders',
          content: `
**Page: Our commitments – For our people**

Nawale, one of our contact center directors: Maxime Riché  
Byron, a Free Center sales advisor: Maxime Riché  
Ning, a logistics manager: Maxime Riché  
Murat, a telecoms technician: Maxime Riché  

**Page: Our commitments – For the environment**  

Software simulation of the Freebox Pop: Alexandre Da Silva  

**Page: Our commitments – For society**  

Free Fiber advertising campaign – September 2019  
The Group designs its own in-house security solutions: Alexandre Da Silva  
A vocational digital technology project set up in 2017 by the Innov’Avenir not-for-profit organization: Les entreprises pour la cite  

**Page: Networks – Our vision**  

In 2020, Free topped the 2 million subscriber mark for fiber in 2020  

**Page: Careers – Why join us?**  

Launching of the Freebox Pop: Sylvain Leurent  
The first year group of work-study placement students at Free University - January 2021: Maxime Riché  
Graduation ceremony for our experience-based learning program: Jacques Chung 
`
        }
      ]
    }
  }[language]
}
