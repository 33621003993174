import { IData } from 'shared/types/dataType'

export const devise = ({ language }: IData) => {
  return {
    fr: {
      header: {
        banner: {
          title: 'Devise',
          image: {
            alt: 'actualites',
            urlMobile: '/assets/images/groupe/devise/devise-banner-mobile.png',
            urlDesktop: '/assets/images/groupe/devise/devise-banner-desktop.png'
          }
        },
        standFirst:
          'Nous sommes un Groupe européen, né en France. Notre tempérament révolutionnaire vient sûrement de là… Un tempérament que résume bien notre devise : liberté, vérité, simplicité.'
      },
      paragraphs: [
        {
          isQuinconce: true,
          hasNoMargins: true,
          title: 'Liberté',
          content:
            'Forts de la conviction que les consommateurs doivent être libres, nous avons bouleversé le marché des Télécoms avec nos offres historiques **sans engagement**. La liberté, c’est aussi la **défense du consommateur**. Nous avons toujours combattu les monopoles, les positions dominantes qui restreignent les choix d’achat. La liberté, c’est enfin la qualité des connexions qui permet la **liberté des usages**. Grâce à la fibre, grâce à la 4G et à la 5G, nous dessinons un monde où chacun peut être, partout et tout le temps, sans entrave, relié au monde.',
          image: '/assets/images/groupe/devise/liberte.gif',
          alt: 'liberté'
        },
        {
          isQuinconce: true,
          hasNoMargins: true,
          title: 'Vérité',
          content:
            'Nous militons depuis toujours pour le **juste prix** des télécommunications. Nous avons ainsi été les premiers à démocratiser les Appels et les SMS en illimité avec notre Forfait Free à moins de 20€. Nous investissons massivement dans nos infrastructures pour délivrer un service au meilleur coût. La vérité, c’est aussi dire clairement quels sont les **impacts de notre activité sur l’environnement** et veiller à les réduire au maximum. Et nous poursuivons sans relâche cet effort.',
          image: '/assets/images/groupe/devise/verite.gif',
          alt: 'vérité'
        },
        {
          isQuinconce: true,
          hasNoMargins: true,
          title: 'Simplicité',
          content:
            'Nos produits sont à la pointe de l’innovation technologique, mais nous avons toujours voulu que leur utilisation ne soit **jamais un problème**. Nos offres commerciales sont **ultra simples et lisibles**, à l’image de notre 2h-2€. Cette simplicité ne vient pas de nulle part. Elle est le reflet direct de notre fonctionnement interne. Nous n’aimons pas les structures hiérarchiques lourdes, qui complexifient les prises de décision. Nous privilégions la **flexibilité, l’agilité et l’autonomie**.',
          image: '/assets/images/groupe/devise/simplicite.gif',
          alt: 'simplicité'
        }
      ]
    },
    en: {
      header: {
        banner: {
          title: 'Our motto',
          image: {
            alt: 'news',
            urlMobile: '/assets/images/groupe/devise/devise-banner-uk-mobile.png',
            urlDesktop: '/assets/images/groupe/devise/devise-banner-uk-desktop.png'
          }
        },
        standFirst:
          'We’re a European group born in France. And that’s undoubtedly where our revolutionary approach comes from. An approach that’s embodied in our motto: liberty, honesty, simplicity.'
      },
      paragraphs: [
        {
          isQuinconce: true,
          hasNoMargins: true,
          title: 'Liberty',
          content:
            'Driven by a resolute belief in consumer freedom, we shook up the French telecoms market right from the outset with **our no-commitment plans**. For us, liberty involves **defending consumer rights**, and we’ve always fought against monopolies and dominant positions which restrict consumer choices. And liberty’s also about having high-quality connectivity, which **frees up usages**. Thanks to fiber, 4G and 5G, we’re shaping a world where everyone can be globally connected, anywhere, at any time, and with no barriers.',
          image: '/assets/images/groupe/devise/liberte.gif',
          alt: 'Liberty'
        },
        {
          isQuinconce: true,
          hasNoMargins: true,
          title: 'Honesty',
          content:
            'We’ve always campaigned for **fair prices** in the telecoms sector, and were the first operator to bring unlimited calls and text messages within everyone’s reach thanks to our Free Mobile Plan costing less than €20 a month. We invest massively in our infrastructure to be able to deliver our services at **the best possible cost**. Honesty also means clearly stating **how our business impacts the environment** and constantly doing everything we can to minimize those impacts.',
          image: '/assets/images/groupe/devise/verite.gif',
          alt: 'Honesty'
        },
        {
          isQuinconce: true,
          hasNoMargins: true,
          title: 'Simplicity',
          content:
            "Our products are at the cutting edge of technological innovation, but we’ve always sought to make them **easy to use**. Our plans and packages are ultra-clear and straightforward, such as our 2 hours-for-€2 plan. This simplicity doesn't come out of the blue – it’s a direct reflection of **how our Group is organized**. We don't like heavy hierarchical structures that complicate decision-making and much prefer **flexibility, agility and autonomy**.",
          image: '/assets/images/groupe/devise/simplicite.gif',
          alt: 'Simplicity'
        }
      ]
    }
  }[language]
}
