import { List } from 'immutable'
import axios from 'axios'
import { configurationsService } from 'shared/services/ConfigurationsService'
import { navigate } from 'hookrouter'
import { routeTemplates } from 'components/routes'
import { useTranslations } from './useTranslations'
import { useLanguage } from 'store/locale/hooks/useLanguage'
import { DateTime } from 'luxon'
import { IOrderedTag } from 'shared/types'
import { useStaticSWR } from 'shared/hooks/useStaticSWR'

export interface IPublication {
  date: DateTime
  title: string
  description: string
  link: string
  imageUrl: string
  isRestricted: boolean
  tags: string[]
  publicationState?: string | null
}

export enum PublicationType {
  ARCEPIndicators = 'arcep-indicators',
  Documentation = 'documentation',
  FinancialReleases = 'financial-releases',
  FinancialResults = 'financial-results',
  PressReleases = 'press-releases',
  RegulatedInformation = 'regulated-information',
  RseReleases = 'publications-all',
  ShareholdersMeetings = 'shareholder-meetings',
  LegalDocuments = 'legal-documents',
  ESGDocuments = 'esg-documents',
  News = 'news'
}

export enum EEntity {
  groupe = 'Groupe',
  holding = 'Holding'
}

export interface IPublicationFilter {
  type: PublicationType
  year: string
  entity?: EEntity
  mandatoryTag?: boolean
  tag?: string
  keepAllFilteringTags?: boolean
}

const DEFAULT_TAG = 'all'

const extractPublications = (data: any, language: string): List<IPublication> => {
  return List<IPublication>(
    data
      ? data.pubs.map((publication) => {
          let publicationFileURL = publication.publication_file?.url || ''
          const hasBaseURL = !!publicationFileURL.match(/^https?/)

          if (!hasBaseURL && publicationFileURL !== '') {
            publicationFileURL = configurationsService.getImageFullUrl(publicationFileURL)
          }

          return {
            date: DateTime.fromISO(publication.publication_date).setLocale(language),
            description: publication.description,
            publicationState: publication.publicationState,
            imageUrl: publication.thumbnail?.url
              ? configurationsService.getImageFullUrl(publication.thumbnail.url)
              : configurationsService.getDefaultPublicationIconLink(language),
            isRestricted: publication.is_restricted,
            link: publicationFileURL !== '' ? publicationFileURL : publication.external_link,
            tags: publication.publication_tags?.map(({ code }) => code),
            title: publication.title
          }
        })
      : []
  )
}

const fetcher = (url, language, year, tag, entity) =>
  axios
    .get(url, {
      params: { language: language, year: year, tag: tag, entity: entity }
    })
    .then((result) => result.data)

export const usePublications = ({
  type,
  year,
  mandatoryTag,
  tag,
  entity,
  keepAllFilteringTags
}: IPublicationFilter) => {
  const { language } = useLanguage()
  const t = useTranslations()
  const { data } = useStaticSWR({
    key: [configurationsService.getPublicationEndpoint(type), language, year, tag, entity],
    fetcher
  })

  const placeholder: IOrderedTag = { code: DEFAULT_TAG, name: t('select-tag'), order: -1 }
  const allPublications = extractPublications(data, language)
  const publications =
    tag === DEFAULT_TAG ? allPublications : allPublications.filter((p) => !tag || p.tags.includes(tag))

  const mapTag = (rawTags) =>
    rawTags?.map(
      (tag): IOrderedTag => ({
        code: tag.code,
        order: tag.order,
        name: tag[`name_${language}`]
      })
    )
  const duplicatedTags = [...(mapTag(data?.pubTags) ?? [])]

  const allTags = List<IOrderedTag>(
    duplicatedTags.filter(({ code }, index, self) => self.map(({ code }) => code).indexOf(code) === index)
  )
  const tags =
    keepAllFilteringTags || tag === DEFAULT_TAG
      ? allTags
      : allTags.filter(({ code }) => code === tag || code === DEFAULT_TAG)

  const onYearChange = (year: string) => {
    navigate(routeTemplates[type](language, year, tag, entity))
  }

  const onTagChange = (tag: string) => {
    navigate(routeTemplates[type](language, year, tag, entity))
  }

  if (mandatoryTag && tag === undefined && tags.count() > 1) {
    onTagChange(tags.get(1)?.code || DEFAULT_TAG)
  }

  return {
    isLoading: !data,
    publications,
    tags: [placeholder, ...(tags ?? [])],
    onYearChange,
    onTagChange
  }
}
