import { configurationsService } from 'shared/services/ConfigurationsService'

const { countriesEndpoint, emailEndpoint } = configurationsService

export const apis = {
  email: {
    sendApi: () => `${emailEndpoint}/send`
  },
  countries: {
    getCountriesApi: (language: string) => `${countriesEndpoint}?language=${language}`
  },
  antenna: {
    sendApi: (language: string) => `${emailEndpoint}/antenna?language=${language}`
  },
  simbox: {
    sendApi: (language: string) => `${emailEndpoint}/simbox?language=${language}`
  },
  coordinates: {
    getCoordinates: (address: string) => `https://api-adresse.data.gouv.fr/search/?q=${address}`
  }
}
