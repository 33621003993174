import { IData } from 'shared/types/dataType'

export const personalData = ({ language }: IData) => {
  return {
    fr: {
      title: 'Données personnelles',
      paragraphs: [
        {
          title: 'Politique de confidentialité',
          content: `
La société iliad ainsi que toutes les entités du Groupe iliad (ci-après « iliad ») attachent une grande importance à la protection et à la confidentialité de votre vie privée, de vos données à caractère personnel (ci-après « Données ») et au respect de vos droits en la matière.

Dans ce cadre, la présente politique de confidentialité vise à vous informer de la politique générale à laquelle s'astreint la société iliad en tant qu’éditrice du site internet https://www.iliad.fr/fr/ (ci-après le « Site Internet ») pour respecter votre vie privée et vous permettra de comprendre quelles Données nous collectons et ce que nous en faisons, ainsi que la façon dont vous pouvez protéger votre vie privée.

iliad attire d’ores et déjà votre attention sur le fait que les autres services et sites auxquels vous pouvez accéder à partir du Site Internet sont soumis à leur propre politique de protection des Données. iliad vous invite donc à consulter la politique applicable en matière de protection des Données sur chacun de ces sites internet avant de leur communiquer vos Données.

[Gérer mes consentements](didomi/preferences)
`
        },
        {
          title: 'Données personnelles',
          content: `
On entend par Données toutes les informations vous concernant en tant que personne physique que la société iliad, en tant que responsable de traitement, collecte ou que vous renseignez dans le cadre de votre navigation sur le Site Internet et qui permettent directement ou non de vous identifier.

iliad attire particulièrement l'attention des parents sur l'utilisation faite par leurs enfants de l'Internet. Elle recommande aux parents de veiller à ce que leurs enfants ne communiquent des Données qu'avec leur accord préalable.  

### QUELLES DONNÉES SONT COLLECTÉES PAR ILIAD VOUS CONCERNANT ?

Dans le cadre de son activité et de votre visite sur le Site Internet, iliad collecte de façon loyale et transparente les catégories de Données suivantes que vous nous fournissez directement ou indirectement :
- des Données d’identification telles que vos nom, prénom, adresse mail.
- des Données de connexion telles que votre adresse IP et les cookies.  

iliad s’engage à ne collecter et n’exploiter que des Données adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées. iliad veille ainsi notamment à ce que les Données soient si nécessaire mises à jour régulièrement.

### DE QUELLE FAÇON ILIAD UTILISE-T-ELLE VOS DONNÉES ?

Les traitements de vos Données par iliad sont fondés sur son intérêt légitime de développer et de promouvoir son activité ainsi que les produits et services des entités du Groupe iliad ou, le cas échéant, sur votre consentement pour recevoir des communications des sociétés du Groupe iliad, que vous pouvez retirer à tout moment (via le lien accessible en bas de la page d’accueil du Site Internet).

Vos Données sont utilisées par iliad pour les finalités déterminée, spécifiques et légitimes suivantes :
- vous transmettre des communiqués institutionnels relatifs aux nouveaux produits et services du Groupe iliad (presse, financiers, etc.) ;
- vous inviter aux évènements du Groupe iliad.
- Ces données ne sont pas utilisées à d’autres fins que celles indiquées ci-dessus.  

### QUI PEUT AVOIR ACCÈS À VOS DONNÉES ?

Vos données personnelles sont traitées par iliad pour l’exécution des finalités indiquées dans la présente Politique de confidentialité et peuvent éventuellement être transférées à des prestataires de services tiers qui lui fournissent des services ou une assistance et des conseils pour exécuter les mêmes finalités que celles ici présentées.

Nous veillons à ce que seules les personnes habilitées puissent avoir accès à vos Données. iliad applique des politiques d’habilitation qui permettent que les Données qu’elle traite ne soient transmises qu’aux seules personnes autorisées à y avoir accès.

Sur demande, elles peuvent également être transférées aux personnes et autorités dont le droit d'accès aux données à caractère personnel est reconnu par la loi, les règlements ou les dispositions émanant d'autorités habilitées par la loi.

iliad ne transfère pas vos Données en-dehors de l’Union européenne. Si elle devait avoir besoin de transférer des Données en-dehors de l’Union européenne en dehors des cas dérogatoires, elle ne le ferait qu’après avoir pris les mesures nécessaires et adéquates pour un assurer un niveau de protection et de sécurité de vos Données équivalent à celui proposé en Europe, ce dont elle vous informerait préalablement.

### COMBIEN DE TEMPS ILIAD CONSERVE-T-ELLE VOS DONNÉES ?

Vos Données sont conservées pendant le temps nécessaire à la réalisation des finalités exposées et pour une durée maximale de trois ans à compter de votre retrait de consentement.

iliad peut également utiliser vos Données afin de respecter ses obligations légales et/ou pour répondre aux demandes des autorités publiques et gouvernementales. Ces durées seront conservées pendant la durée légale autorisée uniquement.

### DE QUELLE FAÇON ILIAD ASSURE-T-ELLE LA SÉCURITÉ DE VOS DONNÉES ?

iliad accorde une importance particulière à la sécurité des Données. Elle met en œuvre des mesures techniques et organisationnelles appropriées pour protéger vos Données qu’elle traite et sélectionne rigoureusement ses partenaires et prestataires qui pourraient être amenés à accéder à vos Données.

Vos Données sont traitées de façon électronique et/ou manuelle et en tout état de cause, de façon à ce que leur sécurité, protection et confidentialité soient assurées au regard de leur niveau de sensibilité, via des mesures administratives, techniques et physiques pour prévenir la perte, le vol, l’utilisation, la divulgation ou encore la modification non-autorisées.  

### COMMENT GÉRER VOS DONNÉES ?

iliad est particulièrement soucieuse du respect des droits qui vous sont accordés dans le cadre des traitements de Données qu’elle met en œuvre, pour vous garantir des traitements équitables et transparents compte tenu des circonstances particulières et du contexte dans lesquels vos Données traitées.

À ce titre, vous pouvez à tout moment notamment accéder à vos Données, les rectifier, les faire supprimer ou demander la limitation du traitement de vos Données pour des motifs spécifiques prévus par la règlementation en matière de Données ou en demander la portabilité en adressant un courrier et en justifiant de votre identité à : Free – Informatique et Libertés – 75371 Paris Cedex 08.

Vous disposez également du droit de vous opposer à tout moment, pour des raisons tenant à votre situation particulière, à un traitement de vos Données dont la base juridique est l’intérêt légitime poursuivi par iliad. En cas d’exercice d’un tel droit d’opposition, nous veillerons à ne plus traiter vos Données dans le cadre du traitement concerné sauf si nous pouvons démontrer que nous pouvons avoir des motifs légitimes et impérieux pour maintenir ce traitement. Ces motifs devront être supérieurs à vos intérêts et à vos droits et libertés, ou le traitement se justifier pour la constatation, l'exercice ou la défense de droits en justice.

iliad pourra vous adresser des communications par mail si vous avez donnez votre accord au moment de la collecte de votre adresse mail. Par suite, vous pourrez à tout moment vous y désinscrire par le lien se trouvant dans l’email qui vous a été envoyé.

Vous pouvez à tout moment contacter notre Délégué à la Protection des Données personnelles pour des questions relatives à la protection de vos données personnelles à l’adresse : dpo@iliad.fr. En vous adressant au Délégué à la Protection des Données personnelles, vous pouvez également définir des directives relatives au sort de vos Données après votre décès.

En cas de réclamation à laquelle la société iliad ne vous aurait pas donné de réponse satisfaisante, vous pouvez vous adresser à la Commission Nationale de l’Informatique et des Libertés (CNIL) en charge du respect des obligations en matière de Données.
`
        },
        {
          title: 'Cookies',
          content: `
### QU’EST-CE QU’UN COOKIE ?

Le terme « cookie » regroupe dans la présente Politique, les cookies et technologies utilisées à des fins similaires. Les cookies sont de petits fichiers contenant une chaîne de caractères, stockés par votre navigateur internet sur votre terminal (ordinateur, mobile, tablette etc.) lorsque vous visitez un site internet.

Ils sont utilisés par un site internet pour envoyer des informations à votre navigateur et permettre à ce dernier de renvoyer des informations au site d’origine (par exemple un identifiant de session ou le choix d’une langue).

Un cookie déposé ne permet pas de vous identifier nominativement. En revanche, il enregistre des informations relatives à votre navigation sur le Site Internet, telles que vos préférences par exemple, qui pourront être directement lues par les serveurs de la Société lors de vos visites et requêtes ultérieures sur le Site Internet.

Seul l’émetteur d’un cookie peut lire ou modifier les informations qui y sont contenues.
Il existe différents types de cookies :

- des cookies de session qui disparaissent dès que vous quittez le site internet ;
- des cookies permanents qui demeurent sur votre terminal jusqu’à expiration de leur durée de vie ou jusqu’à ce que vous les supprimiez à l’aide des fonctionnalités de votre navigateur.  

Vous êtes informé que, lors de vos visites sur le Site Internet, des cookies peuvent être installés.

### POUR QUELLES FINALITÉS ILIAD DÉPOSE-T-ELLE DES COOKIES ?

La plupart de cookies qui peuvent être déposés ou lus lors de votre navigation sur le Site Internet nous sont propres et sont des cookies techniques, facilitent la communication par voie électronique ou sont des cookies de mesure d’audience afin que la Société améliore ses services et l’expérience de navigation de son Site Internet.

Les cookies d’analyse d’audience ont une durée de 13 mois et les cookies de session disparaissent une fois que vous quittez le Site Internet.

Aucun cookie de tiers publicitaire n’est déposé sur le Site Internet.

### COMMENT CONTRÔLER LE STOCKAGE DE COOKIES SUR VOTRE TERMINAL ET/OU VOUS Y OPPOSER ?

Sachez tout d’abord que le stockage de cookies sur votre terminal requière votre consentement, sauf lorsque qu’ils sont strictement nécessaires pour vous fournir un service que vous avez demandé ou ont pour finalité exclusive de permettre ou faciliter la communication par voie électronique. Les cookies utilisés exclusivement pour réaliser des statistiques ou mesures d’audience et qui sont anonymes, ne nécessitent pas votre consentement préalable mais vous pouvez vous opposer à leur dépôt sur votre terminal.

Si la plupart des navigateurs sont paramétrés par défaut et acceptent l’installation de cookies vous avez également la possibilité de paramétrer la réception de cookies sur votre terminal au moyen de l’option « préférences Internet » de votre navigateur. Vous pouvez ainsi décider d'accepter l'ensemble des cookies, d'être avertis lors de l'activation d'un cookie, de choisir ceux que vous acceptez selon leur émetteur ou de rejeter l'ensemble des cookies.

Néanmoins, le refus de certains cookies peut réduire votre accès à certaines fonctionnalités du Site Internet, pages ou espaces du Site internet ce dont nous serions être responsables.
Pour la gestion des cookies et de vos choix, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d'aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier vos souhaits en matière de cookies. A titre d’exemple, vous pouvez opposer à l’enregistrement de cookies sur votre terminal en paramétrant votre navigateur de la manière suivante :

#### Firefox

1. Cliquez sur le bouton de **Menu** et sélectionnez **Options**
2. Sélectionnez le panneau **Vie privée et sécurité** et rendez-vous à la section **Historique**.
3. Dans le menu déroulant **Règles de conservation** choisissez **Utiliser les paramètres personnalisés pour l'historique**.
4. Décochez la case **Accepter les cookies**.

#### Google Chrome

1. Cliquez sur **Plus**, puis sur **Paramètres**, puis **Paramètres avancés**.
2. Dans la section **Confidentialité et sécurité**, cliquez sur **Paramètres du contenu**, puis sur **Cookies**.
3. Désactivez **Autoriser les sites à enregistrer/lire les données des cookies**.

#### Microsoft Internet Explorer

1. Cliquez **Outils**, puis **Options Internet**.
2. Sélectionnez l’onglet **Confidentialité**, puis sous **Paramètres**, sélectionnez **Avancé**. Choisissez si vous souhaitez autoriser, bloquer ou être invité à déterminer la configuration voulue pour les cookies internes et tiers.

#### Safari

1. Cliquez sur **Préférences**, puis sur **Confidentialité**.
2. Sélectionnez une option relative aux **Cookies et données de site web**.

#### Opera

1. Cliquez sur **Préférences**, puis **Avancé**.
2. Sélectionnez une option de relative aux **Cookies**.

#### iOS

1. Cliquez sur **Réglages**, puis **Safari**.
2. Dans la rubrique **Confidentialité et sécurité**, activez **Bloquer tous les cookies**.

#### Android

1. Cliquez sur **Plus**, puis sur **Paramètres**, puis **Paramètres du site**.
2. Dans la rubrique **Cookies**, désactivez l’option **Cookies**.

#### Windows Phone

1. Cliquez sur **Paramètres**, puis **Paramètres avancés**.
2. Dans le menu déroulant **Cookies de sites Web**, sélectionnez **Les bloquer tous**.

Enfin, vous pouvez régulièrement détruire les cookies stockés sur votre terminal.
Par ailleurs, n’oubliez pas de paramétrer l’ensemble des navigateurs de vos différents terminaux (tablettes, smartphones, ordinateurs…).

### PLATEFORMES D’OPPOSITION

Vous pouvez également vous rendre sur une des plateformes de professionnels de la publicité qui vous offrent la possibilité de refuser ou d’accepter des cookies utilisés par les sociétés qui en sont adhérentes. Grâce à ces mécanismes centralisés qui ne bloquent pas l’affichage des publicités vous pouvez empêcher l’installation de cookies permettant d’adapter les publicités à vos centres d’intérêts.

Afin d’interdire l’installation de ces cookies sur votre terminal, vous pouvez par exemple vous rendre sur le site de la plateforme européenne centralisée www.youronlinechoices.com qui est partagée et proposée par des centaines de professionnels de la publicité digitale regroupés au sein de l’association européenne EDAA (European Digital Advertising Alliance).

Sur la page http://www.youronlinechoices.com/fr/controler-ses-cookies/ vous pourrez connaître les entreprises inscrites à cette plate-forme et qui vous offrent la possibilité de refuser ou d'accepter les cookies utilisés par ces dernières pour adapter à vos informations de navigation les publicités susceptibles d'être affichées sur votre terminal.
`
        },
        {
          title: 'Évolution de la politique de confidentialité',
          content: `
La présente Politique de confidentialité peut être amenée à évoluer, notamment en raison des évolutions législatives et réglementaires et afin de refléter les évolutions de nos pratiques et technologies. Nous vous invitons ainsi à la consulter régulièrement.
`
        }
      ]
    },
    en: {
      title: 'Personal data',
      paragraphs: [
        {
          title: 'Privacy Policy',
          content: `
iliad and all the entities of the iliad Group (hereinafter referred to as "iliad") attach great importance to protecting the privacy and confidentiality of your personal data (hereinafter referred to as "Data") and to respecting your rights related to personal data.

The purpose of this Privacy Policy is to inform you of the general policy that iliad, as publisher of the website https://www.iliad.en/ (hereinafter the "Website"), adheres to in order to respect your privacy, and to enable you to understand what Data we collect and what we do with it, as well as how you can protect your privacy.

iliad draws your attention to the fact that the other services and websites that you may access from the Website are subject to their own data protection policies. iliad therefore invites you to consult the applicable data protection policy on each of these websites before providing them with your Data.

[Manage my consents](didomi/preferences)
`
        },
        {
          title: 'Personal data',
          content: `
Data means all information concerning you as a natural person that iliad, in its capacity as Data controller, collects or that you provide in the course of your browsing on the Website and which allows you to be identified directly or indirectly. 

iliad particularly draws the attention of parents to their children's Internet use and recommends that parents ensure their children only disclose Data with their prior consent. 

### WHAT DATA DOES ILIAD COLLECT ABOUT YOU?

In the context of its business activities and your use of the Website, iliad collects in a fair and transparent manner the following categories of Data that you provide to us either directly or indirectly: 
- Identification Data such as your surname, first name, email address, etc.
- Connection Data such as your IP address and cookies. 

iliad undertakes to only collect and use Data that is adequate, relevant and necessary for the purposes for which it is processed. In particular, iliad ensures that the Data is updated on a regular basis if necessary. 

### HOW DOES ILIAD USE YOUR DATA? 

Your Data is processed by iliad on the basis of its legitimate interest in developing and promoting its business and the products and services of iliad Group entities or, where applicable, on the basis of your consent to receive communications from iliad Group companies, which you may withdraw at any time (via the link accessible at the bottom of the Website’s home page). 

Your Data is used by iliad for the following specific and legitimate purposes: 
- Sending you corporate releases about the iliad Group's new products and services (news releases, financial information, etc.).
- Sending you invitations to iliad Group events.

Your Data is not used for any other purpose than those mentioned above.

### WHO CAN ACCESS YOUR DATA?

Your Data is processed by iliad for the purposes stated in this Privacy Policy and may potentially be transferred to third party service providers who provide services or assistance and advice for carrying out the same purposes as those stated herein. 

We ensure that only authorized persons have access to your Data. iliad applies authorization policies that ensure that the Data it processes is only transmitted to persons who are authorized to access it. 

Upon request, the Data may also be transferred to persons and authorities whose right to access personal data is recognized by law, regulations or provisions emanating from authorities empowered by law. 

iliad does not transfer your Data outside the European Union. If it were required to transfer Data outside the European Union other than in the cases provided for in the derogations, it would do so only after having taken the necessary and adequate measures to ensure that your Data is subject to a level of protection and security equivalent to that offered in Europe and you would be informed beforehand. 

### HOW LONG DOES ILIAD KEEP YOUR DATA?

Your Data will be kept for the time necessary to achieve the stated purposes and for a maximum period of three years from the date of your withdrawal of consent. 

iliad may also use your Data in order to comply with its legal obligations and/or to respond to requests from public and governmental authorities. Such Data will be kept for the legally authorized period only. 

### HOW DOES ILIAD ENSURE THE SECURITY OF YOUR DATA?

iliad considers Data security to be of utmost importance. It implements appropriate technical and organizational measures to protect the Data that it processes and rigorously selects its partners and service providers who may have access to your Data. 

Your Data is processed electronically and/or manually and in all cases in such a way as to ensure its security, protection and confidentiality in accordance with its level of sensitivity, through administrative, technical and physical measures to prevent loss, theft, unauthorized use, disclosure or modification. 

### HOW CAN YOU MANAGE YOUR DATA?

iliad takes particular care to respect the rights granted to you within the framework of the Data processing it carries out, in order to guarantee fair and transparent processing, taking into account the specific circumstances and context in which your Data is processed. 

You may, at any time, access your Data, rectify it, have it deleted, request that the processing of your Data be limited for the specific reasons provided for in the applicable data protection regulations, or request the portability of your Data, by sending a letter and proof of your identity to: Free - Informatique et Libertés - 75371 Paris Cedex 08 - France. 

You also have the right to object at any time, for reasons relating to your specific situation, to the processing of your Data carried out on the legal basis of iliad's legitimate interest. If you exercise such a right to object, we will ensure that we no longer process your Data in the context of the processing in question unless we can demonstrate that we have legitimate and compelling reasons to continue such processing. These grounds must override your interests and your rights and freedoms, or the processing must be justified for the establishment, exercise or defense of legal claims.

iliad may send you e-mails if you give your consent at the time your e-mail address is collected. Subsequently, you may unsubscribe at any time using the link in the e-mail sent to you. 

You can contact our Data Protection Officer at any time regarding issues relating to the protection of your personal data at the following address: dpo@iliad.fr. By contacting the Data Protection Officer, you can also define guidelines regarding the handling of your Data after your death. 

In the event of a complaint which has not received a satisfactory response from iliad, you may contact the Commission Nationale de l'Informatique et des Libertés (CNIL), the French data protection authority, which is in charge of ensuring compliance with Data protection obligations. 
`
        },
        {
          title: 'Cookies',
          content: `
### WHAT IS A COOKIE?

The term "cookie" in this Policy encompasses cookies as well as technologies used for similar purposes. Cookies are small files containing a string of characters that are placed by your web browser on your device (computer, mobile, tablet, etc.) when you visit a website. Websites use cookies to send information to your browser and allow your browser to send information back to the originating site (for example, a session ID or language selection). 

A cookie that has been stored does not allow you to be identified by name. Cookies record information relating to your browsing on a website, such as your preferences for example, which may be directly read by the servers of the company concerned when you visit the website again or make a request on the website.

Only the issuer of a cookie can read or modify the information it contains. There are several different types of cookies: 
- Session cookies that disappear as soon as you leave the website.
- Permanent cookies that remain on your device until their lifetime expires or until you delete them using the features of your browser.

You are informed that cookies may be installed during your visits to the Website. 

### FOR WHAT PURPOSES DOES ILIAD USE COOKIES?

Most of the cookies that may be placed or read during your browsing on the Website are unique to us and are technical cookies, facilitate electronic communication or are audience analysis cookies enabling iliad to improve its services and the Website browsing experience. 

The audience analysis cookies last for 13 months and the session cookies disappear once you leave the Website. 

No cookies from third party advertisers are placed on the Website. 

### HOW CAN YOU CONTROL AND/OR BLOCK THE STORAGE OF COOKIES ON YOUR DEVICE?

The storage of cookies on your device requires your consent, except when they are strictly necessary to provide you with a service that you have requested or their sole purpose is to enable or facilitate communication by electronic means. Cookies used exclusively for statistical or audience measurement purposes and which are anonymous, do not require your prior consent, but you may object to them being placed on your device. 

While most browsers are set by default to accept the installation of cookies, you can also configure what your device does with cookies using your browser's "Internet preferences" option. You can decide to accept all cookies, to be notified when a cookie is activated, to choose which cookies you accept according to their originator or to reject all cookies. However, refusing certain cookies may reduce your access to certain features, pages or areas of the Website for which we are responsible. 

The settings for managing cookies and your choices are different for each browser. They are described in the help menu of your browser, which will tell you how to change your cookie preferences. For example, you can prevent cookies from being saved on your device by setting your browser as follows : 

#### Firefox

1. Click the **Menu** button and select **Options**
2. Select the **Privacy and Security** tab and go to the **History** section.
3. From the **Storage Settings** drop-down menu select **Use Custom Settings for History**.
4. Clear the **Accept Cookies** box.


#### Google Chrome

1. Click **More**, click **Settings**, and then click **Advanced Settings**.
2. In the **Privacy and Security** section, click **Content Settings**, and then click **Cookies**. 
3. Disable **Allow sites to save/read cookie data**

#### Microsoft Internet Explorer

1. Click **Tools**, then **Internet Options**.
2. Select the **Privacy** tab, then under **Settings**, select **Advanced**. Choose whether you want to allow, block or be prompted to set the desired configuration for internal and third party cookies.

#### Safari

1. Click **Preferences**, and then click **Privacy**. 
2. Select an option for **Cookies and website data**.

#### Opera

1. Click **Preferences**, then **Advanced**. 
2. Select an option for **Cookies**.

#### iOS

1. Click **Settings**, and then **Safari**.
2. Under **Privacy and Security**, enable **Block All Cookies**.

#### Android

1. Click **More**, then **Settings**, then **Site Settings**.
2. In the **Cookies** section, disable the **Cookies** option.

#### Windows Phone

1. Click **Settings**, and then click **Advanced Settings**.
2. From the **Web site cookies** drop-down menu, select **Block All Cookies**.

Additionally, you can regularly delete the cookies stored on your device.
Remember to configure all the browsers of your different devices (tablets, smartphones, computers, etc.).

### PLATFORMS FOR LODGING AN OBJECTION

You can also go to one of the advertising professionals' platforms that offer you the possibility of refusing or accepting cookies used by the companies that are members. Through these centralized mechanisms, which do not block the display of advertisements, you can block the installation of cookies enabling advertisements to be tailored to your interests. 

In order to block the installation of these cookies on your device, you can, for example, go to the site of the centralized European platform www.youronlinechoices.com which is shared and offered by hundreds of digital advertising professionals within the European Digital Advertising Alliance (EDAA). 

On the http://www.youronlinechoices.com/fr/controler-ses-cookies/ page, you can find out which companies have registered with this platform and offer you the possibility of refusing or accepting the cookies they use to adapt the advertisements that may be displayed on your device to your browsing information. 
`
        },
        {
          title: 'Changes to the Privacy Policy',
          content: `
This Privacy Policy is subject to change, particularly as a result of legislative and regulatory developments and in order to reflect changes in our practices and technologies. We therefore recommend that you consult it on a regular basis.
`
        }
      ]
    }
  }[language]
}
