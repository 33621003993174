import { resolveAsset } from 'shared/assets'
import { IData } from 'shared/types/dataType'

export const twentyYearsInnovation = ({ language, breakpoint }: IData) => {
  return {
    fr: {
      header: {
        banner: {
          title: "25 ans d'innovation",
          image: {
            alt: "25 ans d'innovation",
            urlMobile:
              '/assets/images/groupe/twenty-years-innovation/twenty-five-years-of-innovation-banner-mobile.png',
            urlDesktop:
              '/assets/images/groupe/twenty-years-innovation/twenty-five-years-of-innovation-banner-desktop.png'
          }
        },
        standFirst:
          'Depuis 1999, iliad mène une formidable aventure entrepreneuriale, en misant sur l’innovation technologique pour libérer les usages. En 25 ans, nous avons bien grandi, mais nous avons gardé cet état d’esprit inimitable qui nous a toujours poussés à faire les choses à notre manière : l’esprit Free.'
      },
      imageIntro: `${resolveAsset('/assets/images/groupe/twenty-years-innovation/innovation.gif', breakpoint)}`,
      paragraphs: []
    },
    en: {
      header: {
        banner: {
          title: '25 years of innovation',
          image: {
            alt: '25 years of innovation',
            urlMobile:
              '/assets/images/groupe/twenty-years-innovation/twenty-five-years-of-innovation-banner-uk-mobile.png',
            urlDesktop:
              '/assets/images/groupe/twenty-years-innovation/twenty-five-years-of-innovation-banner-uk-desktop.png'
          }
        },
        standFirst:
          'Since 1999, iliad has been living an amazing entrepreneurial adventure, pursuing technological innovation to free up different types of usages. We’ve grown a lot in the last 25 years, but we’ve never lost our unique Free spirit that has always driven us to do things our own way. '
      },
      imageIntro: `${resolveAsset('/assets/images/groupe/twenty-years-innovation/innovation-uk.gif', breakpoint)}`,
      paragraphs: []
    }
  }[language]
}
