import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { IApplicationState } from '../../combiners'
import { DateTime } from 'luxon'
import { navigate, usePath } from 'hookrouter'
import { routeTemplates } from 'components/routes'

export const useLanguage = () => {
  const { language, defaultLanguage, defaultLuxonCode, availableLanguages } = useSelector(
    createSelector(
      (state: IApplicationState) => state.locale.currentLanguage,
      (state: IApplicationState) => state.locale.defaultLanguage,
      (state: IApplicationState) => state.locale.defaultLuxonCode,
      (state: IApplicationState) => state.locale.availableLanguages,
      (language, defaultLanguage, defaultLuxonCode, availableLanguages) => ({
        language,
        defaultLanguage,
        defaultLuxonCode,
        availableLanguages
      })
    )
  )

  const languageLuxonLocale = availableLanguages.find(({ code }) => code === language)?.luxonCode ?? defaultLuxonCode

  const getDateNow = () => DateTime.local().setLocale(language)
  const getDateFromShortDate = (date: Date) => {
    return DateTime.fromJSDate(date).setLocale(language)
  }

  const toShortDateString = (date: DateTime, separator?: string) => {
    const localeDate = date.toLocaleString(dateFormats.DATE_SHORT)
    return (separator !== undefined ? localeDate?.split('/').join(separator) : localeDate) || ''
  }

  const toFullDateString = (date: DateTime) => date.toLocaleString(dateFormats.DATE_FULL) || ''

  const dateFormats = {
    DATE_FULL: DateTime.DATE_FULL,
    TIME_24: DateTime.TIME_24_SIMPLE,
    DATE_SHORT: DateTime.DATE_SHORT
  }

  const path = usePath()
  const onLanguageChange = (language: string) => navigate(routeTemplates['on-language-change'](language, path))

  return {
    language,
    languageLuxonLocale,
    defaultLanguage,
    availableLanguages,
    onLanguageChange,
    getDateNow,
    getDateFromShortDate,
    dateFormats,
    toShortDateString,
    toFullDateString
  }
}
