import { IData } from 'shared/types/dataType'

export const vision = ({ language, breakpoint }: IData) => {
  return {
    fr: {
      header: {
        banner: {
          image: {
            alt: 'actualites',
            urlMobile: '/assets/images/network/vision/vision-mobile.png',
            urlDesktop: '/assets/images/network/vision/vision-desktop.png'
          },
          title: 'Vision'
        }
      }
    },
    en: {
      header: {
        banner: {
          image: {
            alt: 'iliad vision',
            urlMobile: '/assets/images/network/vision/vision-uk-mobile.png',
            urlDesktop: '/assets/images/network/vision/vision-uk-desktop.png'
          },
          title: 'Our Vision'
        }
      },
      paragraphs: [
        {
          isQuinconce: true,
          isFirstImageLeft: true,
          title: 'Equal access across the country',
          content: `
Our network strategy began in France **in 2002 with ADSL and unbundling**. We’ve unbundled more than 15,000 subscriber connection nodes, which means that nearly **all of the French population** now has access to **our triple-play services**. In areas where fiber hasn’t yet arrived, the Group systematically participates in operations to **ramp up speeds** on the copper network to ensure they’re as fast as possible.

<br>

Having invested nearly €3.9 billion, the Group is **working hard on deploying fiber** across France, including in **sparsely populated areas**. Our aim is to be a long-term partner for local authorities and invest alongside them in the rollouts of regional-level networks, known as “public initiative networks” or PINs. We’re already present on 70 Public Initiative Networks (PIN), and our objective is to **rapidly be on all of them**. Since the third quarter of 2021, Free has had more Fiber subscribers than ADSL subscribers.
Our target for 2022 is to have 30 million connectible Fiber sockets and over 5 million subscribers in 2023.
`,
          image: '/assets/images/network/vision/reseau-technicien.jpg',
          alt: 'Free 2020 2 million subscriber mark fiber',
          caption: 'Free topped the 3 million subscriber mark for fiber in 2021'
        },
        {
          isQuinconce: true,
          isFirstImageLeft: true,
          content: `
Our Group took the gamble of building its own mobile telephony network starting from **2011**, i.e. 15 years after France’s other operators. To make up for this lost time, we had to go at an unprecedented pace, **building more than 24,456 sites in ten years**, compared with the 20 years it took the others before us to build their mobile networks. We’re now opening 2,000 new sites a year on average. The Group has the largest 5G network in France in terms of number of sites, with 16,754 5G Free sites, including 4,681 in 3.5GHz as of May 1, 2023 (ANFR).

<br>

Our mobile rollout drive covers all regions of France, even the **most rural**. Over the last years, almost one in two mobile sites built by iliad has been in a non-densely populated area. In January 2018, we stepped up our actions for rural regions by signing up to the **“New Deal Mobile”**, a nationwide program launched by the French government to provide good quality mobile coverage for the entire French population. For iliad, this means building **5,000 new sites** in areas with little or no coverage, and **expanding 4G coverage** across France.
`,
          image: '/assets/images/network/vision/reseau-helico.jpg',
          alt: 'mobile mast helicopter',
          caption: 'A mobile mast arriving by helicopter in Triel-sur-Seine (France) in June 2020'
        },
        // A CONSERVER ------------------->
        //         {
        //           content: `
        // <div class="videoWrapper">
        // <iframe
        //   src=https://player.vimeo.com/video/477603991?title=0&byline=0&portrait=0
        //   frameBorder="0"
        //   allow="autoplay; fullscreen"
        //   allowFullScreen
        // ></iframe>
        // </div>
        // `
        //         },
        // A CONSERVER <-------------------
        {
          isQuinconce: true,
          isFirstImageLeft: true,
          title: 'Free in France’s overseas territories',
          content: `
In 2016, iliad was allocated mobile frequencies in the French overseas territories, and the Free Mobile Plan was **launched on Reunion Island in July 2017**. This clearly demonstrates the Group’s intention to put an end to the tariff discrimination that exists between mainland France and its overseas territories. In May 2022, we have extended our strategy of providing attractive, fair-priced offerings to all of France’s overseas territories where iliad has radio frequencies (**Guadeloupe, French Guiana, Martinique, Saint-Barthélemy and Saint-Martin**).
`,
          image: '/assets/images/network/vision/outre-mer.png',
          alt: 'Free France’s overseas territories',
          caption: 'Free Mobile’s launch on Reunion Island - July 2017'
        },
        {
          isQuinconce: true,
          isFirstImageLeft: true,
          title: 'Building close relations',
          content: `
Because of our regional investments, our teams can build close relations with **our subscribers and local authorities**.

<br>

The deployment of mobile masts can cause concern among the public and reticence from local authorities. We therefore have a dedicated team that **handles relations with local authorities** at each stage of these projects. Additionally, Free Mobile has signed **charters** with around a hundred towns for setting up its mobile masts. These charters are aimed at ensuring the regional rollout of digital infrastructure goes smoothly.

<br>

Lastly, our employees working throughout France are closely attuned to the needs of local residents. We have **more than 1200** people working in our network of over 200 stores, 3,600 people in the field who deploy and maintain our networks on a daily basis, and 1,000 roving technicians who go out to our subscribers’ homes. At the beginning of December 2022, the Group has strengthen its local strategy with the launch of Free Proxi, the new local support service. Throughout France, small local teams made up of 8 to 10 Free agents are on hand for subscribers, in their neighborhoods, to help them with all their requests, covering both Freebox and Free Mobile plans. Today, around one hundred teams are deployed across the country.
`,

          image: '/assets/images/network/vision/freecenter.jpg',
          alt: 'Free Center in France',
          caption: 'A Free Center in France'
        },
        {
          isQuinconce: true,
          isFirstImageLeft: true,
          title: 'The "rivoluzione iliad" in Italy',
          content: `
iliad entered the Italian mobile telephony market in May 2018. The “rivoluzione iliad” was an immediate success, and in the space of four years, we’ve reached **10 million subscribers** mark in Italy.

<br>

We currently have over 900 people working in Italy, dedicated to making sure that our subscribers have best-in-class service quality, rolling out our mobile infrastructure nationwide and developing our distribution network. Today, iliad Italia has 36 stores in Italy’s and over 4,000 points of sales including SIM card dispensers ("Simboxes"). Thanks to these Simboxes – a highly innovative and totally secure service that takes just three minutes to deliver a SIM card – we’ve revolutionized the telecoms distribution system in Italy.

<br>

In January 2022, drawing on the success of its mobile business in Italy,  iliad Italy has entered the Fixed market with a Full Fiber plan and a dedicated box called the "iliadbox". Last May, iliad Italia launched its latest revolution, iliadbusiness, the mobile offer for entreprise and business, embedding all the iliad Italia key values.
`,
          image: '/assets/images/network/vision/iliad.jpg',
          alt: 'iliad store Italy',
          caption: 'An iliad store in Italy'
        }
      ],
      notaBene: [{ text: 'Subscriber connection node' }]
    }
  }[language]
}
