import { createStyles, makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      '& span': {
        fontSize: '0.875rem'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0',
        gridTemplateColumns: 'repeat(2, 1fr)'
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0',
        gridTemplateColumns: 'repeat(2, 1fr)'
      }
    },
    label: {
      display: 'flex',
      fontSize: '1rem',
      marginBottom: '0',
      marginTop: 10,
      marginRight: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0.375rem'
      }
    },
    containerdescription: {
      margin: '1.5rem 0 1.5rem 0',
      padding: '0 1.875rem',
      [theme.breakpoints.down('sm')]: {
        padding: '0'
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0'
      },
      '& label': {
        display: 'block'
      }
    },
    wrapperfield: {
      display: 'grid',
      gridTemplateColumns: '1fr 3fr',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    },
    inputpostal: {
      width: '100%',
      maxWidth: '12.25rem'
    },
    textarea: {
      width: '100%'
    },
    input: {
      width: '100%',
      maxWidth: '21.875rem',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'inherit'
      }
    }
  })
)
