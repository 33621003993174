export class TextService {
  static truncate = (text, limit) => (text.length > limit ? `${text.slice(0, limit)}...` : text)

  static routifyName = (title: string | undefined) =>
    title
      ? title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .split('/')
          .join('-')
          .toLowerCase()
          ?.split(' ')
          .join('-')
      : ''

  static validateEmail = (mail: string) => /^([\w\d._\-#])+@([\w\d._\-#]+[.][\w\d._\-#]+)+$/.test(mail)
}
