export const tribute = ({ language }: any) => {
  return {
    fr: {
      header: {
        title: 'Hommage à Nicolas Jaeger'
      },
      paragraphe: [
        'Nous avons créé ce livre de condoléances numérique pour que toutes celles et ceux qui ont eu la chance de travailler avec Nicolas chez Free – Groupe iliad puissent lui rendre hommage. Vous pouvez laisser un mot et, si vous le souhaitez, partager une photo souvenir avec Nicolas.',
        'Tous les hommages seront transmis à sa famille. Vous pouvez choisir de publier votre hommage pour que celui-ci soit visible par tous ou choisir de l’adresser en privé à ses proches.',
        'Merci à toutes et tous.'
      ],
      buttonMessage: 'Postez un message',
      form: {
        lastname: 'Nom',
        firstname: 'Prénom',
        email: 'Email',
        message: 'Votre message',
        displayMessage: 'Envoyer sans afficher sur la page',
        image: 'Choisir une image :'
      },
      buttonSumit: 'Envoyez votre message',
      tributes: 'Les hommages',
      thankMessage: 'Votre hommage a bien été pris en compte et sera transmis à la famille',
      lastnameRequired: 'Le nom est requis',
      firstnameRequired: 'Le prénom est requis',
      messageRequired: 'Ce champ est requis',
      invalidFormatEmail: "Le format de l'email n'est pas valide"
    },
    en: {
      header: {
        title: 'Tribute to Nicolas Jaeger'
      },
      paragraphe: [
        'We have created this online book of condolence so that everyone who had the good fortune to work with Nicolas at Free – iliad Group can pay tribute to him. You can leave a message and, if you wish to, share a memory photo with Nicolas.',
        'All of the tributes will be communicated to Nicolas’ family. You can either choose to post your tribute so that everyone can see it or you can address it in private to his loved ones.',
        'Thank you.'
      ],
      buttonMessage: 'Post a message',
      form: {
        lastname: 'Lastname',
        firstname: 'Firstname',
        email: 'Email',
        message: 'Your message',
        displayMessage: 'Send without displaying on page',
        image: 'Select an image :'
      },
      buttonSumit: 'Send your message',
      tributes: 'Tributes',
      thankMessage: 'Your tribute has been taken into account and will be forwarded to the family.',
      lastnameRequired: 'Lastname is required',
      firstnameRequired: 'Firstname is required',
      messageRequired: 'This field is required',
      invalidFormatEmail: 'Invalid email format'
    }
  }[language]
}
