import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { TextInput } from 'components/templates/RelayAntenna/TextInput/TextInput'
import { useFormik } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import { configurationsService } from '../../shared/services/ConfigurationsService'
import { useRecaptcha } from 'hooks/useRecaptcha'
import { TextService } from 'shared/services/TextService'
import { useLanguage } from 'store/locale/hooks/useLanguage'
import { tribute } from 'data/group/tribute'

export const TributeView = () => {
  const [file, setFile] = useState<File | null>(null)
  const [showForm, setShowForm] = useState(false)
  const [messages, setMessages] = useState([])
  const recaptchaRef = useRef<ReCAPTCHA>()
  const [isInvalideEmail, setIsInvalideEmail] = useState(false)
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { siteverify } = useRecaptcha()
  const { language } = useLanguage()
  const tributeData = tribute({ language })
  const fetchMessages = async () => {
    const response = await fetch(configurationsService.hommage)
    const data = await response.json()
    setMessages(data.reverse())
  }

  useEffect(() => {
    fetchMessages()
  }, [])

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }

  const fetchPostMesssage = async (formData, resetForm) => {
    try {
      const response = await fetch(configurationsService.hommage, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`)
      } else if (response.ok) {
        setDisplaySuccessMessage(true)
        await fetchMessages()
        setShowForm(false)
        setIsLoading(false)
        resetForm()
      }
    } catch (err) {
      console.error("Erreur lors de l'envoi du formulaire:", err)
    }
  }

  const validate = (values: any) => {
    const errors: any = {}

    if (!values.lastName) {
      errors.lastName = tributeData.lastnameRequired
    } else if (values.lastName.length > 15) {
      errors.lastName = 'Must be 15 characters or less'
    }

    if (!values.firstName) {
      errors.firstName = tributeData.firstnameRequired
    } else if (values.firstName.length > 20) {
      errors.firstName = 'Must be 20 characters or less'
    }

    if (!values.message) {
      errors.message = tributeData.messageRequired
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      lastName: '',
      firstName: '',
      email: '',
      message: '',
      displayOnPage: false
    },
    validate,
    onSubmit: async (values, { resetForm }) => {
      const isValideEmail = TextService.validateEmail(values.email)
      if (isValideEmail || values.email === '') {
        setIsInvalideEmail(false)
        setIsLoading(true)
        const token = await recaptchaRef.current.executeAsync()
        recaptchaRef.current.reset()

        if (token) {
          const isTokenValid = await siteverify(token)
          if (isTokenValid) {
            const displayMessage = values.displayOnPage ? 'false' : 'true'
            const formData = new FormData()
            formData.append('lastname', values.lastName)
            formData.append('firstname', values.firstName)
            if (values.email) {
              formData.append('email', values.email)
            }
            formData.append('message', values.message)
            formData.append('displayOnPage', displayMessage)

            if (file) {
              formData.append('image', file)
            }

            fetchPostMesssage(formData, resetForm)
          }
        }
      } else {
        setIsInvalideEmail(true)
      }
    }
  })

  return (
    <section style={{ maxWidth: '800px', margin: '0 auto' }}>
      <header style={{ textAlign: 'center' }}>
        <img
          src="/assets/images/tribute/logo-groupe-iliad.png"
          alt="Logo Groupe Iliad"
          style={{ display: 'block', maxWidth: '100px', margin: '0 auto' }}
        />
        <h1 style={{ fontWeight: 'bold', fontSize: '36px' }}>{tributeData.header.title}</h1>
      </header>
      <main>
        <img
          src="/assets/images/tribute/nicolas-jaeger.png"
          alt="portrait Nicolas JAEGER"
          style={{ display: 'block', maxWidth: '600px', margin: '0 auto', width: '100%' }}
        />
        {tributeData.paragraphe.map((text) => (
          <p>{text}</p>
        ))}
        {showForm && <hr style={{ borderTop: '1px dashed black', margin: '40px 0' }} />}

        <div style={{ textAlign: 'center' }}>
          {!showForm && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              disableRipple
              onClick={() => setShowForm(true)}
            >
              {tributeData.buttonMessage}
            </Button>
          )}
        </div>

        {showForm && (
          <form noValidate autoComplete="off" onSubmit={formik.handleSubmit} style={{ marginTop: '40px' }}>
            <Grid>
              <TextInput
                styles={{ marginBottom: '20px', gridTemplateColumns: 'minmax(auto, 100px) 1fr' }}
                errors={formik.errors}
                handleChange={formik.handleChange}
                values={formik.values as any}
                field="lastName"
                text={tributeData.form.lastname}
              />
              <TextInput
                styles={{ marginBottom: '20px', gridTemplateColumns: 'minmax(auto, 100px) 1fr' }}
                errors={formik.errors}
                handleChange={formik.handleChange}
                values={formik.values as any}
                field="firstName"
                text={tributeData.form.firstname}
              />
              <TextInput
                styles={{ marginBottom: '30px', gridTemplateColumns: 'minmax(auto, 100px) 1fr' }}
                errors={formik.errors}
                handleChange={formik.handleChange}
                values={formik.values as any}
                field="email"
                text={tributeData.form.email}
              />
              {isInvalideEmail && <p>{tributeData.invalidFormatEmail}</p>}
            </Grid>
            <div>
              <p style={{ marginBottom: '10px', fontSize: '16px' }}>{tributeData.form.message}</p>
              <textarea
                id="message"
                name="message"
                onChange={formik.handleChange}
                value={formik.values.message}
                style={{ width: '100%', minHeight: '200px' }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
              <label htmlFor="displayOnPage">{tributeData.form.displayMessage}</label>
              <input
                type="checkbox"
                id="displayOnPage"
                name="displayOnPage"
                onChange={formik.handleChange}
                checked={formik.values.displayOnPage}
                style={{ height: '20px', width: '20px', marginLeft: '5px' }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
              <label htmlFor="image">{tributeData.form.image}</label>
              <input
                type="file"
                id="image"
                name="image"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
                style={{ marginLeft: '10px' }}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                disableRipple
                disabled={isLoading}
              >
                {tributeData.buttonSumit}
              </Button>
            </div>
          </form>
        )}
        {displaySuccessMessage && (
          <p style={{ fontWeight: 'bold', backgroundColor: '#f6f6f6', padding: '20px', textAlign: 'center' }}>
            {tributeData.thankMessage}
          </p>
        )}
        <div className="w-full flex justify-center z-50">
          <ReCAPTCHA ref={recaptchaRef} sitekey={configurationsService.recaptchaPublicKey} size="invisible" />
        </div>
      </main>

      <hr style={{ borderTop: '1px dashed black', margin: '40px 0' }} />

      <h2 style={{ marginBottom: '16px' }}>{tributeData.tributes}</h2>
      {messages &&
        messages.map((message: any) => {
          return (
            <div
              key={message.id}
              style={{
                border: '1px solid #e7e7e7',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                marginBottom: '40px',
                padding: '14px'
              }}
            >
              <div>
                <p>{message.message}</p>
                {message.image && (
                  <img
                    src={message.image}
                    alt="message"
                    style={{ maxWidth: '300px', display: 'block', height: 'auto', margin: '0 auto' }}
                  />
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  gap: '10px'
                }}
              >
                <div>
                  <span>{message.firstname}</span>&nbsp;<span>{message.lastname}</span>
                </div>
              </div>
            </div>
          )
        })}
    </section>
  )
}
