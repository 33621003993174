import packageInfo from '../../../package.json'

export const resolveAsset = (pattern: string, breakpoint: string | undefined, index?: number): string => {
  if (!breakpoint) return ''

  const dotIndex = pattern.lastIndexOf('.')
  const prefix = dotIndex > 0 ? pattern.substring(0, dotIndex) : pattern
  const extension = dotIndex > 0 ? pattern.substring(dotIndex) : ''
  const assetIndex = index !== undefined ? `${index.toString().padStart(6, '0')}-` : ''

  return `${prefix}-${assetIndex}${breakpoint}${extension}?apphash=${packageInfo.version}`
}
