import { IData } from 'shared/types/dataType'

export const societyCommitments = ({ language, breakpoint }: IData) => {
  return {
    fr: {
      header: {
        banner: {
          title: 'Pour la société',
          image: {
            alt: 'actualites',
            urlMobile: '/assets/images/commitments/society-commitments/society-commitments-mobile.png',
            urlDesktop: '/assets/images/commitments/society-commitments/society-commitments-desktop.png'
          }
        },
        standFirst:
          'Depuis toujours, nous défendons les droits du consommateur, qu’il s’agisse du juste prix de sa facture télécoms ou de la protection de ses données personnelles. Cette approche a inscrit d’emblée l’activité de notre Groupe dans un projet sociétal militant, relayé depuis 2006 par la Fondation Free.'
      },
      paragraphs: [
        {
          isQuinconce: true,
          title: 'Le juste prix de la facture télécoms',
          content:
            "C’est le **pilier de notre politique commerciale**. Depuis sa création, le Groupe défend l’idée du juste prix pour le panier télécoms. Avec un objectif simple : **démocratiser l’accès aux nouvelles technologies**. L’offre initiale à 2 euros par mois de Free Mobile en est le symbole. L’ARCEP a montré que le lancement de Free Mobile a considérablement réduit la facture moyenne des abonnés mobile en France en l’établissant à 14,60€ par mois. Ce faisant, nous avons significativement amélioré le **pouvoir d’achat des consommateurs**. Ce sont ainsi 13 milliards d’euros de pouvoir d’achat qui leur ont été rendus sur la période 2012-2014 suite à l’attribution de la quatrième licence mobile. Imaginez le montant que cela représente sur 10 ans ! Au cours des 10 dernières années, nous n’avons pas augmenté nos prix sur nos forfaits mobiles 2€ et 19,99€. A l'occasion des 10 ans de Free Mobile, nous avons pris un engagement fort : celui de ne pas toucher aux prix de ces 2 forfaits mobiles pour les 5 prochaines années, jusqu’en 2027.",
          image: '/assets/images/commitments/society-commitments/fibre.jpg',
          alt: 'visuel offre 2 heures = 2 euros',
          caption: 'Campagne publicitaire pour la Fibre Free – Septembre 2019'
        },
        {
          isQuinconce: true,
          title: 'Un environnement numérique de confiance',
          content:
            'iliad défend les **libertés de ses abonnés**. Comme nous nous y sommes engagés dans notre charte de protection des données personnelles, **la confidentialité des données** nous est toujours apparue comme un droit individuel fondamental. L’explosion récente des usages numériques impose néanmoins d’aller plus loin pour instaurer un environnement de confiance. Les abonnés ont le droit de savoir que leurs données sont entre de bonnes mains. Pour garantir le secret des correspondances et des données échangées sur nos réseaux, nous développons **nos propres solutions de sécurité**, comme le chiffrage de toutes les liaisons sans fil avec les algorithmes les plus récents. Nos équipes informatiques ont mis au point des solutions pour **protéger nos abonnés du phishing**. Notre politique d’internalisation des équipements, des plateformes de relation abonnés et du système d’information nous permet de maîtriser les risques.',
          image: '/assets/images/commitments/society-commitments/societe-securite.jpg',
          alt: 'visuel à créer avec cadenas pour symboliser sécurité des données',
          caption: 'Le Groupe conçoit en interne ses propres solutions de sécurité'
        },
        {
          isQuinconce: true,
          title: 'Une société numérique inclusive',
          content:
            'Depuis 2006, [la Fondation d’entreprise Free](https://www.fondation-free.fr) agit contre l’**exclusion numérique** – parce que le numérique est un formidable vecteur de développement personnel et professionnel – et pour la **promotion du logiciel libre** – parce que cette dynamique de partage est un puissant accélérateur de l’innovation et de la création. Chaque année, des **appels à projets** sont lancés et des **financements** sont attribués. Un **soutien technique**, notamment l’hébergement sur nos serveurs, peut aussi être apporté. Au total, ce sont déjà plus de **300 projets** de toutes tailles qui ont été soutenus. Par ailleurs les collaborateurs d’iliad sont encouragés, pour quelques heures, ou sur une base plus régulière, à donner de leur temps et à mettre à disposition leurs multiples talents.',
          image: '/assets/images/commitments/society-commitments/societe-numerique.jpg',
          alt: 'logo Fondation Free',
          caption: 'Projet DécouvPro de l’association Innov’Avenir - Femmes et numérique - 2017'
        }
      ]
    },
    en: {
      header: {
        banner: {
          title: 'For society',
          image: {
            alt: 'For society',
            urlMobile: '/assets/images/commitments/society-commitments/society-commitments-uk-mobile.png',
            urlDesktop: '/assets/images/commitments/society-commitments/society-commitments-uk-desktop.png'
          }
        },
        standFirst:
          'At iliad, we’ve always stood up for consumer rights, whether about fair prices for phone bills or protecting personal data. This approach immediately positioned us as campaigners for social justice, a role that was taken up by the Free Foundation in 2006. '
      },
      paragraphs: [
        {
          isQuinconce: true,
          title: 'Fair prices for phone bills',
          content:
            "This is the **central pillar of our sales strategy**. Since the Group was created, we’ve defended the principle of paying a fair price for a phone package, with one simple objective in mind: to **bring new technologies within everyone’s reach**. Our first Free Mobile plan, costing just €2 a month, clearly illustrates this aim. And since the launch of Free Mobile the average mobile phone bill in France has reduced significantly, with ARCEP (the French telecoms regulator) now estimating it at €14.60 per month. This means we’ve been able to significantly **improve consumer purchasing power**, with a total of €13 billion in purchasing power given back to consumers between 2012 and 2014 after we were granted France’s fourth mobile operator license. Imagine what that represents over ten years. Free has not increased the price of its mobile plans (€2 and €19.99) for 10 years. In January 2022, for Free Mobile's 10th anniversary, Free has pledged not to do so for the coming 5 years, until 2027.",
          image: '/assets/images/commitments/society-commitments/fibre.jpg',
          caption: 'Free Fiber advertising campaign – September 2019'
        },
        {
          isQuinconce: true,
          title: 'A secure digital environment',
          content:
            'iliad respects and upholds the **freedoms of its subscribers**. iliad respects and upholds the freedoms of its subscribers. As stated in our Personal Data Protection Charter, we have always viewed **data confidentiality** as a fundamental individual right. However, the recent surge in digital usages means even more steps need to be taken to create an environment of trust. Subscribers have the right to know that **their data is in good hands**. To guarantee the confidentiality of calls and data exchanged on our networks, we’ve developed **proprietary security solutions**, such as encryption of all wireless links using the most up-to-date algorithms. Our IT teams have also designed solutions to protect our subscribers from phishing. And our strategy of using **proprietary equipment**, having our own subscriber relations platforms and an internally-developed information system enables us to control data protection risks.',
          image: '/assets/images/commitments/society-commitments/societe-securite.jpg',
          alt: 'iliad designs in-house security solutions',
          caption: 'The Group designs its own in-house security solutions'
        },
        {
          isQuinconce: true,
          title: 'An inclusive digital society',
          content:
            'Because digital technology is an excellent vector for personal and professional development, since it was formed in 2006, [Free’s corporate foundation](https://www.fondation-free.fr) has been taking action to **combat digital exclusion**. The Free Foundation’s other main focus is **promoting open-source software**, because having a shared approach to development is a powerful driver of innovation and creation. Each year, calls for projects are launched and funding is granted to the successful applicants. **Technical support** can also be provided to charities and other non-profit organizations, notably hosting on our servers. Altogether, since its beginnings the Free Foundation has backed **over 300 projects of varying sizes**. At the same time, iliad encourages **its people to get involved** in voluntary work by giving their time and talent to community projects, either for a few hours or on a more regular basis.',
          image: '/assets/images/commitments/society-commitments/societe-numerique.jpg',
          alt: 'Free Foundation logo',
          caption:
            'An introduction to digital professions for women, organized by the Innov’Avenir not-for-profit organization in 2017'
        }
      ]
    }
  }[language]
}
