import { IData } from 'shared/types/dataType'

export const ethicCompliance = ({ language }: IData) => {
  return {
    fr: {
      header: {
        banner: {
          title: 'Ethique et conformité',
          image: {
            alt: 'actualites',
            urlMobile: '/assets/images/groupe/ethique-conformite/ethic-compliance-banner-mobile.png',
            urlDesktop: '/assets/images/groupe/ethique-conformite/ethic-compliance-banner-desktop.png'
          }
        },
        standFirst:
          'Qu’il s’agisse de droit des affaires, de droit des citoyens ou de relations avec nos fournisseurs, nous sommes engagés dans un processus d’amélioration continue de nos standards de conformité. Un responsable Éthique & Conformité pilote l’ensemble des actions du Groupe en ce sens.'
      },
      paragraphs: [
        {
          isQuinconce: true,
          title: 'Lutter contre la corruption',
          content:
            'Le Groupe iliad a toujours accordé une place de choix à la question de l’éthique de ses affaires. Récemment, nous avons encore renforcé nos **organes de contrôle** en matière de transparence et de lutte contre la corruption. Un **[nouveau code éthique](https://www.iliad.fr/fr/publications/all/ethics-compliance)** et un **[code de conduite anticorruption](https://www.iliad.fr/fr/publications/all/ethics-compliance)**, réalisés en concertation avec nos équipes, ont été adoptés. Ils sont diffusés à tous les collaborateurs. En conformité avec la loi Sapin II, iliad a par ailleurs créé un **[dispositif digital d’alerte éthique](https://alerteethique.iliad.fr/)** qui permet de signaler des cas de corruption, mais aussi de discrimination ou de harcèlement.',
          image: '/assets/images/groupe/ethique-conformite/Anim-anticorruption.gif',
          alt: 'accueil site compliance.iliad.fr'
        },
        {
          isQuinconce: true,
          title: 'Une nouvelle politique d’achats responsables',
          content:
            'En parallèle, nous avons mis en place courant 2018 une nouvelle politique achats qui repose sur **l’évaluation RSE** de nos partenaires et de notre chaîne d’approvisionnement. Nous attendons d’eux l’exemplarité sur trois sujets : l’éthique des affaires, la protection de l’environnement et le respect des droits sociaux et de l’homme. iliad a développé une **[solution interne de gestion des fournisseurs](https://fournisseurs.iliad.fr)** et de suivi de leur conformité et noué un **partenariat** avec EcoVadis. Cette plateforme, utilisée par plus de 50 000 entreprises dans 150 pays, a été retenue pour sa méthode d’évaluation fondée sur les référentiels RSE internationaux et des preuves tangibles.',
          image: '/assets/images/groupe/ethique-conformite/Compteurs.gif',
          alt: 'accueil plateforme EcoVadis sur 1 écran web'
        },
        {
          isQuinconce: true,
          title: 'Données personnelles : un Groupe engagé',
          content:
            'En tant qu’opérateur télécoms, iliad est un acteur incontournable du numérique, impliqué à ce titre dans la **protection des données personnelles de ses abonnés**. Le Groupe s’engage à promouvoir un environnement numérique de confiance et de sécurité qui s’articule autour de l’information, de la transparence, du consentement et du contrôle par les personnes concernées de leurs données personnelles. iliad a mis en place un dispositif de gouvernance dédié, basé sur la coordination d’un **réseau de référents**, par métier et activité. Celui-ci a pour mission d’assurer la protection des données personnelles dans le cadre de la **loi informatique et liberté** et du **RGPD**, tels que la procédure de notification des incidents, l’exercice des droits des abonnés ou encore le *Privacy by design*.',
          image: '/assets/images/groupe/ethique-conformite/Cadenas.gif',
          alt: 'accueil photo réunion réseau de référents RGPD fin juin / début juillet'
        }
      ]
    },
    en: {
      header: {
        banner: {
          title: 'Ethics & Compliance',
          image: {
            alt: 'ethics compliance',
            urlMobile: '/assets/images/groupe/ethique-conformite/ethic-compliance-banner-uk-mobile.png',
            urlDesktop: '/assets/images/groupe/ethique-conformite/ethic-compliance-banner-uk-desktop.png'
          }
        },
        standFirst:
          'We’re fully committed to acting with the utmost integrity in everything we do, whether in our business activities or our relations with suppliers or society in general. That’s why we have a continuous improvement process in place for our compliance standards and have appointed a Chief Ethics & Compliance Officer who oversees all of the Group’s actions and initiatives in these areas.'
      },
      paragraphs: [
        {
          isQuinconce: true,
          title: 'Anti-corruption measures',
          content:
            'The iliad Group has always placed the utmost importance on conducting business ethically. We recently further reinforced our **transparency and anti-corruption oversight bodies** and adopted a new **Code of Ethics** drawn up in consultation with our teams, which is distributed to all employees. Additionally, in compliance with the new French law on transparency and anti-corruption (the “Sapin II” Act), we have set up a **digital whistle-blowing system** which can be used to report any cases of corruption, discrimination or harassment.',
          image: '/assets/images/groupe/ethique-conformite/Anim-anticorruption.gif',
          alt: ''
        },
        {
          isQuinconce: true,
          title: 'A new responsible procurement policy',
          content:
            'In 2018, we put in place a **new procurement policy** which incorporates an assessment of the CSR performances of our partners and all of the links in our supply chain, who we expect to meet the highest standards in three main areas: business ethics, environmental protection and respect for labor and human rights. We have developed an in-house solution for **managing suppliers and assessing their compliance**. For the purpose of these assessments, we have entered into a partnership with the **EcoVadis ratings platform**, which is used by over 50,000 companies in 150 countries, and was selected in view of its international CSR benchmarks and tried and tested results.',
          image: '/assets/images/groupe/ethique-conformite/Compteurs_en.gif',
          alt: ''
        },
        {
          isQuinconce: true,
          title: 'Committed to protecting personal data',
          content:
            'As a telecom company, iliad plays an important role in the digital arena, which means it has a responsibility to **protect its subscribers’ personal data**. We are committed to providing a **secure and trustworthy digital environment** for personal data and strictly respecting data subjects’ rights to full information, transparency, consent and control of the data they provide. iliad has set up a **specific data protection governance system**, which is coordinated by a network of personal data protection officers, appointed for each of the Group’s businesses and entities. The underlying aim of this system is to ensure that personal data is protected in accordance with the provisions of the **French Data Protection Act and the GDPR**, such as the procedures for reporting data security breaches and exercising subscribers’ rights, and privacy by design.',
          image: '/assets/images/groupe/ethique-conformite/Cadenas.gif',
          alt: ''
        }
      ]
    }
  }[language]
}
