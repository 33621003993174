import { combineReducers, Reducer } from 'redux'
import { combineEpics, Epic } from 'redux-observable'
import { ILocaleState } from './locale/types'
import { initialLocaleState, localeReducer } from './locale/reducers'
import { countryReducer, initialCountryState } from './countries/reducers'
import { countryEpics } from './countries/epics'
import { ICountryState } from './countries/types'
import { INavigationState } from './navigation/types'
import { initialNavigationState, navigationReducer } from './navigation/reducers'
import { INotificationState } from './notification/types'
import { initialNotificationState, notificationReducer } from './notification/reducers'

export interface IApplicationState {
  locale: ILocaleState
  menu: INavigationState
  notification: INotificationState
  country: ICountryState
}

export const initialApplicationState: IApplicationState = {
  locale: initialLocaleState,
  menu: initialNavigationState,
  notification: initialNotificationState,
  country: initialCountryState
}

export const rootReducers: Reducer<IApplicationState> = combineReducers<IApplicationState>({
  locale: localeReducer,
  menu: navigationReducer,
  notification: notificationReducer,
  country: countryReducer
})

export const rootEpic = combineEpics(countryEpics) as Epic
