import './index.css'
import * as React from 'react'
import * as serviceWorker from './serviceWorker'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import { theme } from 'theme'
import { App } from 'components/App'
import { configureStore } from './store/configureStore'
import { configurationsService } from 'shared/services/ConfigurationsService'
import { useIsMobile } from 'shared/hooks/useIsMobile'
import { init as initApm } from '@elastic/apm-rum'
import { DidomiSDK } from '@didomi/react'
import { SWRConfig } from 'swr'

// Init Elastic APM for performance monitoring:
if (configurationsService.env('APM_SERVER')) {
  initApm({
    serviceName: 'iliad-front',
    serverUrl: configurationsService.env('APM_SERVER'),
    environment: configurationsService.env('GITLAB_ENVIRONMENT_NAME'),
    distributedTracingOrigins: [configurationsService.env('REACT_APP_STRAPI_URL')]
  })
}

const store = configureStore()

const Root = () => {
  const isMobile = useIsMobile()

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        {/*  @ts-ignore */}
        <SnackbarProvider maxSnack={3}>
          <SWRConfig value={{}}>
            {isMobile && <CssBaseline />}
            <App />
          </SWRConfig>
        </SnackbarProvider>
        {/*  @ts-ignore */}
        <DidomiSDK
          apiKey={configurationsService.env('REACT_APP_DIDOMI_API_KEY')}
          iabVersion={1}
          gdprAppliesGlobally={true}
          config={{
            notice: {
              type: 'optin'
            }
          }}
        />
      </Provider>
    </ThemeProvider>
  )
}

render(<Root />, document.getElementById('root'))

serviceWorker.unregister()
