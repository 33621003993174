import { ILocaleState, LocaleAction, LocaleActionKind } from './types'
import { List } from 'immutable'

export const initialLocaleState: ILocaleState = {
  availableLanguages: List([
    { code: 'fr', shortName: 'FR', luxonCode: 'fr-fr' },
    { code: 'en', shortName: 'EN', luxonCode: 'en-us' }
  ]), // TODO : information côté backend à terme
  defaultLanguage: 'fr', // TODO : information côté backend à terme
  defaultLuxonCode: 'fr-fr',
  currentLanguage: '',
  texts: { data: {} }
}

export const localeReducer = (state: ILocaleState = initialLocaleState, action: LocaleAction) => {
  switch (action.type) {
    case LocaleActionKind.UpdateLanguage:
      return {
        ...state,
        currentLanguage: action.meta.language
      }
    default:
      return state
  }
}
