import { Grid, TextField, useMediaQuery } from '@material-ui/core'
import { FormikErrors } from 'formik'
import React from 'react'
import { theme } from 'theme'
import { IFormValues } from '../Parts/Parts.interfaces'
import { useStyles } from './TextInput.styles'
import Box from '@material-ui/core/Box'

interface ITextInput {
  text: string
  field: keyof IFormValues
  styles?: React.CSSProperties | undefined
  values: IFormValues
  errors: FormikErrors<IFormValues>
  hasGridContainer?: boolean
  className?: 'input' | 'inputpostal' | 'textarea'
  handleChange: (e: React.ChangeEvent<any>) => void
}

export const TextInput = ({
  text,
  field,
  styles,
  values,
  errors,
  handleChange,
  className = 'input',
  hasGridContainer = false
}: ITextInput) => {
  const classes = useStyles()
  const isXS = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Grid
      className={field === 'textarea' ? classes.containerdescription : classes.wrapperfield}
      style={styles && field !== 'textarea' ? styles : undefined}
    >
      <Box sx={{ display: { isXS: 'block' } }}>
        <Grid item className={classes.label} style={styles && field === 'textarea' ? styles : undefined}>
          {text}
        </Grid>
      </Box>
      {hasGridContainer ? (
        <Grid item>
          <TextField
            name={field}
            onChange={handleChange}
            required
            size="small"
            variant="outlined"
            label={isXS && field !== 'textarea' ? text : null}
            className={classes[className]}
            value={values[field]}
            // @ts-ignore
            helperText={errors[field]}
            error={!!errors[field]}
            {...(field === 'textarea' ? { rows: 5, multiline: true } : {})}
          />
        </Grid>
      ) : (
        <TextField
          name={field}
          onChange={handleChange}
          required
          size="small"
          variant="outlined"
          label={isXS && text}
          className={classes[className]}
          value={values[field]}
          // @ts-ignore
          helperText={errors[field]}
          error={!!errors[field]}
        />
      )}
    </Grid>
  )
}
