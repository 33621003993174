import { applyMiddleware, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { composeWithDevTools } from 'redux-devtools-extension'
import { IApplicationState, initialApplicationState, rootEpic, rootReducers } from './combiners'

const epicMiddleware = createEpicMiddleware()

const enhancer = composeWithDevTools(applyMiddleware(epicMiddleware))

export const configureStore = (initialState: IApplicationState = initialApplicationState) => {
  const store = createStore(rootReducers, initialState, enhancer)
  epicMiddleware.run(rootEpic)
  return store
}
