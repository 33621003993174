declare global {
  interface Window {
    _paq: any
    dataLayer: any
  }
}

export interface ITracking {
  category: string
  action: string
  name?: string
  value?: number
}

export interface ISiteSearch {
  keyword: string
  category: string
  count: number
}

export const trackPageView = () => {
  if (!window._paq) {
    window._paq = []
  }

  window._paq.push(['setCustomUrl', (document as any).URL])
  window._paq.push(['setDocumentTitle', (document as any).title])
  window._paq.push(['trackPageView'])
}

export const trackEvent = ({ category, action, name, value }: ITracking) => {
  if (!window._paq) {
    window._paq = []
  }

  window._paq.push(['trackEvent', category, action, name, value])
}

export const trackSiteSearch = ({ keyword, category, count }: ISiteSearch) => {
  if (!window._paq) {
    window._paq = []
  }

  window._paq.push(['trackSiteSearch', keyword, category, count])
}
