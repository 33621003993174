import { CountryActionKind, ICountrySet, IFetch, IUpdate } from './types'
import { List } from 'immutable'

export const fetchCountries = (language: string): IFetch => ({
  type: CountryActionKind.Fetch,
  meta: { language }
})

export const updateCountries = (countries: List<ICountrySet>): IUpdate => ({
  type: CountryActionKind.Update,
  payload: { countries }
})
