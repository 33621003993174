import { configurationsService } from '../shared/services/ConfigurationsService'
import { useState } from 'react'

export const useRecaptcha = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean | null>(null)

  const siteverify = async (token: string) => {
    setIsLoading(true)

    const formData = new FormData()
    formData.append('token', token)

    const url = configurationsService.getRecaptchaEndpoint()

    const response = await fetch(url, {
      method: 'POST',
      body: formData
    })

    const content = await response.text()

    setSuccess(response.status === 200 && !!content)
    setIsLoading(false)

    return content
  }

  return {
    siteverify,
    isLoading,
    success
  }
}
